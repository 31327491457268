import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import { AreaLayout, AreaSections } from '../../../Utils/area'
import { ComponentTypes } from '../../../Utils/layout'

const PropertyAreas = (props: any) => {
  const { sessionStore, propertyStore }: any = useStore()
  const { isAdmin } = sessionStore
  const { property, hasUnsavedChanges }  = propertyStore
  const { t } = useTranslation()
  
  const accessAndDriveways: any = useRef({})
  const waterDrainage: any = useRef({})
  const lawnAreasAndPlantings: any = useRef({})
  const buildingSides: any = useRef({})
  const outdoorParking: any = useRef({})
  const outdoorFixturesAndEquipment: any = useRef({})
  const yardDeck: any = useRef({})
  const sideBuildings: any = useRef({})
  
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  const [openSections, setOpenSections] = useState([
    ...AreaLayout.map((section: any) => section.title)
  ])

  useEffect(() => {
    if (property) {
      for (const [key, value] of Object.entries(property?.accessAndDriveways || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'materials') {
            accessAndDriveways.current[key] = property?.accessAndDriveways?.[key]?.length ? 
              property?.accessAndDriveways?.[key]?.split(',') :
              []
          } else {
            accessAndDriveways.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(property?.waterDrainage || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          waterDrainage.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.lawnAreasAndPlantings || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          lawnAreasAndPlantings.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.buildingSides || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          buildingSides.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.outdoorParking || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          outdoorParking.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.outdoorFixturesAndEquipment || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          outdoorFixturesAndEquipment.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.yardDeck || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          yardDeck.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(property?.sideBuildings || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          sideBuildings.current[key] = value
        }
      }
      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [property])

  const onSave = () => {
    const updatedProperty = {
      accessAndDriveways: {
        ...accessAndDriveways.current,
        materials: accessAndDriveways.current?.materials?.join(',')
      },
      waterDrainage: waterDrainage.current,
      lawnAreasAndPlantings: lawnAreasAndPlantings.current,
      buildingSides: buildingSides.current,
      outdoorParking: outdoorParking.current,
      outdoorFixturesAndEquipment: outdoorFixturesAndEquipment.current,
      yardDeck: yardDeck.current,
      sideBuildings: sideBuildings.current
    }
    propertyStore.updatePropertyAreas(props?.uuid, updatedProperty)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    []
  )

  useImperativeHandle(
    props?.setUnsavedChanges,
    () => ({ setUnsavedChanges: (value: boolean) => props.setUnsavedChanges(value) }),
    []
  )

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const onChange = (category: string, key: string, value: any) => {
    if (category === AreaSections.AccessAndDriveways) {
      accessAndDriveways.current = { ...(accessAndDriveways?.current || {}), [key]: value }
    } else if (category === AreaSections.WaterDrainage) {
      waterDrainage.current = { ...(waterDrainage?.current || {}), [key]: value }
    } else if (category === AreaSections.LawnAreasAndPlantings) {
      lawnAreasAndPlantings.current = { ...(lawnAreasAndPlantings?.current || {}), [key]: value }
    } else if (category === AreaSections.BuildingSides) {
      buildingSides.current = { ...(buildingSides?.current || {}), [key]: value }
    } else if (category === AreaSections.OutdoorParking) {
      outdoorParking.current = { ...(outdoorParking?.current || {}), [key]: value }
    } else if (category === AreaSections.OutdoorFixturesAndEquipment) {
      outdoorFixturesAndEquipment.current = { ...(outdoorFixturesAndEquipment?.current || {}), [key]: value }
    } else if (category === AreaSections.YardDeck) {
      yardDeck.current = { ...(yardDeck?.current || {}), [key]: value }
    } else if (category === AreaSections.SideBuildings) {
      sideBuildings.current = { ...(sideBuildings?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${key}_${value}`)
    if (!hasUnsavedChanges) {
      propertyStore.setHasUnsavedChanges(true)
    }
  }

  const getValues = useCallback((category: string) => {
    if (category === AreaSections.AccessAndDriveways) {
      return accessAndDriveways?.current || {}
    } else if (category === AreaSections.WaterDrainage) {
      return waterDrainage?.current || {}
    } else if (category === AreaSections.LawnAreasAndPlantings) {
      return lawnAreasAndPlantings?.current || {}
    } else if (category === AreaSections.BuildingSides) {
      return buildingSides?.current || {}
    } else if (category === AreaSections.OutdoorParking) {
      return outdoorParking?.current || {}
    } else if (category === AreaSections.OutdoorFixturesAndEquipment) {
      return outdoorFixturesAndEquipment?.current || {}
    } else if (category === AreaSections.YardDeck) {
      return yardDeck?.current || {}
    } else if (category === AreaSections.SideBuildings) {
      return sideBuildings?.current || {}
    }
  }, [accessAndDriveways, waterDrainage, lawnAreasAndPlantings, buildingSides, outdoorParking, outdoorFixturesAndEquipment, yardDeck, sideBuildings])

  const renderCustomForm = (sections: any, title: string, index: number) => {
    if (sections?.length) {
      // Extract values from the current category
      const values = getValues(title)
      // Filter out items that should not be visible
      const items = sections.map((section: any) => {
        if (!section?.visibleIfValues && !section?.items && !section?.items?.find((item: any) => item?.visibleIfValues)) {
          // If no visibleIfValues are defined, show the item
          return section
        }
        if (!section?.visibleIfValues) {
          if (section?.items) {
            return {
              ...section,
              // Filter out items that should not be visible
              items: section?.items?.filter((item: any) => {
                if (!item?.visibleIfValues) {
                  return true
                }
                return item?.visibleIfValues?.every((visibleIfValue: any) => {
                  if (Array.isArray(values?.[visibleIfValue.key])) {
                    return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
                  }
                  // const values = getValues(visibleIfValue.key?.split('_')[0])
                  return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
                })
              })
            }
          }
        }
        // Check if all visibleIfValues are met
        const conditionsMet = section?.visibleIfValues?.every((visibleIfValue: any) => {
          if (Array.isArray(values?.[visibleIfValue.key])) {
            return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
          }
          // const values = getValues(visibleIfValue.key?.split('_')[0])
          return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
        })
        if (conditionsMet) {
          return section
        }
        return null
      }).filter((item: any) => item)

      if (items?.length === 1 && items[0]?.type === ComponentTypes.Subtitle) {
        return null
      }
      if (!items?.length) {
        return null
      }
      return (
        <CustomForm
          key={`form-${title}-${index}`}
          section={items}
          values={values}
          onChange={(key: string, value: string) => onChange(title, key, value)}
          lastUpdatedValue={lastUpdatedValue}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title: string): any => {
    return sections.map((section: any, index: number) => renderCustomForm(section, title, index))
  }

  const AreaSectionsLayout = useMemo(() => {
    return AreaLayout.map((layout: any) => {
      if (!layout?.sections?.length) {
        return null
      }
      return (
        <CollapseSection
          key={`section-${layout.title}`}
          title={t(layout?.label ?? layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections, layout.title)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue])

  // Do not show anything if params are missing
  const uuid = props?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{AreaSectionsLayout}</Box>
    </Box>
  )
}

export default observer(PropertyAreas)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  },
  loading: {
    margin: 'auto'
  }
} as const
