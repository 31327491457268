// import DashboardIcon from '../Assets/Icons/dashboard.svg'
import PropertyIcon from '../Assets/Icons/residential.svg'
// import ReportIcon from '../Assets/Icons/file.svg'
import NotificationIcon from '../Assets/Icons/bell.svg'
import UserIcon from '../Assets/Icons/users.svg'
import SupportIcon from '../Assets/Icons/customer-service.svg'
import { Option } from './layout'

export enum UserRoles {
  Admin = 'admin',
  PropertyManager = 'property_manager',
  // Shareholder = 'shareholder'
}

export const UserRolesWithProperty: any = [UserRoles.PropertyManager]
// export const UserRolesWithApartment: any = [UserRoles.Shareholder]

export enum UserExtraRoles {
  BoardMember = 'board_member',
  Resident = 'resident',
  Tenant = 'tenant'
}

export enum UserStatuses {
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived'
}

export enum PropertyTypes {
  Condominium = 'condominium'
}

export enum BuildingTypes {
  Condominium = 'condominium'
}

export enum BuildingPurposes {
  Residential = 'residential',
  Commercial = 'commercial'
}

export enum PropertySpacesAndAreas {
  // Säilytysullakko
  StorageAttic = 'storage_attic',
  // Kerhohuone
  ClubRoom = 'club_room',
  // Ulkovälinevarasto
  OutdoorEquipmentStorage = 'outdoor_equipment_storage',
  // Väestönsuoja
  CivilDefenceShelter = 'civil_defense_shelter',
  // Saunaosasto
  SaunaArea = 'sauna_area',
  // Askarteluhuone
  CraftRoom = 'craft_room',
  // Pesutupa
  LaundryArea = 'laundry_area',
  // Mankeli
  Mangle = 'mangle',
  // Uima-allasosasto
  SwimmingPoolArea = 'swimming_pool_area',
  // Huoneistokohtaiset varastot
  ApartmentSpecificStorage = 'apartment_specific_storage',
  // Muu
  Other = 'other'
}

export enum ProjectTypes {
  // Putkiremontti
  PipeRepair = 'pipe_repair',
  // Julkisivuremontti
  FacadeRenovation = 'facade_renovation',
  // Kattoremontti
  RoofRenovation = 'roof_renovation',
  // Ikkunaremontti
  WindowRenovation = 'window_renovation',
  // Parvekeremontti
  BalconyRenovation = 'balcony_renovation',
  // Hissiremontti
  ElevatorRenovation = 'elevator_renovation',
  // Piha-alueen uudistus
  YardRenovation = 'yard_renovation'
}

export enum ProjectTargets {
  // Rakennus
  Building = 'building',
  // Huoneisto
  Apartment = 'apartment',
  // Yleinen tila
  CommonSpace = 'common_space',
  // Tekninen tila
  TechnicalSpace = 'technical_space',
  // Ulkorakennus
  Outbuilding = 'outbuilding',
  // Piha-alue
  Yard = 'yard'
}

export enum FinancingTypes {
  // Laina
  Loan = 'loan'
}

export enum CostTypes {
  // Energia
  Energy = 'energy',
  // Hallinto
  Management = 'management',
  // Kiinteistövero
  PropertyTax = 'property_tax',
  // Lainat
  Loans = 'loans',
  // Jätehuolto
  WasteManagement = 'waste_management',
  // Vesi
  Water = 'water',
  // Isännöinti
  PropertyManagement = 'property_management',
}

export const CostTypeOptions = [
  Option(CostTypes.Energy),
  Option(CostTypes.Management),
  Option(CostTypes.PropertyTax),
  Option(CostTypes.Loans),
  Option(CostTypes.WasteManagement),
  Option(CostTypes.Water),
  Option(CostTypes.PropertyManagement)
]

export enum GraphTypes {
  // Viivakaavio
  Line = 'line',
  // Pylväskaavio
  Bar = 'bar',
  // Ympyräkaavio
  // Pie = 'pie'
}

const GraphColors = {
  [CostTypes.Energy]: '#54BEBE',
  [CostTypes.Management]: '#76C8C8',
  [CostTypes.PropertyTax]: '#A6E0E0',
  [CostTypes.Loans]: '#DEDAD2',
  [CostTypes.WasteManagement]: '#E4BCAD',
  [CostTypes.Water]: '#DF979E',
  [CostTypes.PropertyManagement]: '#D7658B',
  // 'prediction': '#E7E7E7'
}

export const DefaultGraphColors: any = {
  [CostTypes.Energy]: GraphColors[CostTypes.Energy],
  [CostTypes.Management]: GraphColors[CostTypes.Management],
  [CostTypes.PropertyTax]: GraphColors[CostTypes.PropertyTax],
  [CostTypes.Loans]: GraphColors[CostTypes.Loans],
  [CostTypes.WasteManagement]: GraphColors[CostTypes.WasteManagement],
  [CostTypes.Water]: GraphColors[CostTypes.Water],
  [CostTypes.PropertyManagement]: GraphColors[CostTypes.PropertyManagement],
  // 'prediction': GraphColors['prediction']
}

export enum GraphScales {
  Default = 'default',
}

export enum GraphVisibilityOptions {
  Visible = 'visible',
  Hidden = 'hidden'
}

export enum OfferTypes {
  // Kiinteä hinta
  FixedPrice = 'fixed_price',
  // Tuntilaskutus
  HourlyBilling = 'hourly_billing'
}

export enum ApartmentTypes {
  OneRoomPlusKitchen = 'one_room_kitchen',
  TwoRoomsPlusKitchen = 'two_rooms_kitchen',
  ThreeRoomsPlusKitchen = 'three_rooms_kitchen',
  FourRoomsPlusKitchen = 'four_rooms_kitchen',
  FiveRoomsPlusKitchen = 'five_rooms_kitchen',
  OverFiveRoomsPlusKitchen = 'over_five_rooms_kitchen'
}

export const FileTypes = {
  Image: ['image/jpeg', 'image/png'],
  Pdf: 'application/pdf',
  Doc: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  Excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  Powerpoint: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  Text: 'text/plain'
}

export enum UserTabs {
  GeneralInformation = 'general_information',
  Properties = 'properties',
  Apartments = 'apartments',
  Events = 'events'
}

export enum PropertyTabs {
  BasicInformation = 'basic_information',
  Buildings = 'buildings',
  Apartments = 'apartments',
  Areas = 'areas',
  Photos = 'photos',
  Attachments = 'attachments',
  Reminders = 'reminders',
  Costs = 'cost_development',
}

export enum BuildingTabs {
  BasicInformation = 'basic_information',
  BuildingStructures = 'building_structures',
  BuildingParts = 'building_parts',
  BuildingSystems = 'building_systems',
  BuildingSpaces = 'spaces',
  Photos = 'photos',
  Attachments = 'attachments',
  Reminders = 'reminders',
}

export enum ApartmentTabs {
  BasicInformation = 'basic_information',
  Rooms = 'rooms',
  Photos = 'photos',
  Attachments = 'attachments',
  Reminders = 'reminders',
}

export enum RoomTabs {
  BasicInformation = 'basic_information',
  Floor = 'floor',
  Ceiling = 'ceiling',
  Wall1 = 'wall1',
  Wall2 = 'wall2',
  Wall3 = 'wall3',
  Wall4 = 'wall4',
  Wall5 = 'wall5',
  Wall6 = 'wall6',
  Reminders = 'reminders',
}

export enum SpaceTabs {
  BasicInformation = 'basic_information',
  Floor = 'floor',
  Ceiling = 'ceiling',
  Wall1 = 'wall1',
  Wall2 = 'wall2',
  Wall3 = 'wall3',
  Wall4 = 'wall4',
  Wall5 = 'wall5',
  Wall6 = 'wall6',
  Reminders = 'reminders',
}

export const AvailablePrimaryRoutes = (role: string) => {
  if (role === 'admin') {
    return [
      // { url: '/dash', icon: DashboardIcon, text: 'dashboard', title: 'dashboard' },
      {
        url: '/properties',
        icon: PropertyIcon,
        text: 'properties',
        title: 'properties',
        /*
        children: [
          { url: '/example', icon: null, text: 'example', title: 'example' },
        ]
        */
      },
      // { url: '/reports', icon: ReportIcon, text: 'reports', title: 'reports' }
    ]
  } else if (role === 'property_manager') {
    return [
      // { url: '/dash', icon: DashboardIcon, text: 'dashboard', title: 'dashboard' },
      {
        url: '/properties',
        icon: PropertyIcon,
        text: 'properties',
        title: 'properties',
        /*
        children: [
          { url: '/example', icon: null, text: 'example', title: 'example' },
        ]
        */
      },
      // { url: '/reports', icon: ReportIcon, text: 'reports', title: 'reports' }
    ]
  }
  return []
}

export const AvailableSecondaryRoutes = (role: string) => {
  if (role === 'admin') {
    return [
      { url: '/notifications', icon: NotificationIcon, text: 'notifications', title: 'notifications' },
      { url: '/users', icon: UserIcon, text: 'users', title: 'users' },
      { url: '/support', icon: SupportIcon, text: 'technical_support', title: 'technical_support' }
    ]
  } else if (role === 'property_manager') {
    return [
      { url: '/notifications', icon: NotificationIcon, text: 'notifications', title: 'notifications' },
      { url: '/support', icon: SupportIcon, text: 'technical_support', title: 'technical_support' }
      // { url: '/users', icon: UserIcon, text: 'users', title: 'users' }
    ]
  }
  return []
}

export enum Lang { fi = 'fi' }

// Generate years from 1900 to current year
export const BuildingYearOptions = Array
  .from(new Array(new Date().getFullYear() + 1 - 1900), (val, index) => 1900 + index)
  .map((item: number) => {
    return { label: item.toString(), value: item.toString() }
  }).reverse()

export const FinnishMunicipalities: any = [
  'Akaa',
  'Alajärvi',
  'Alavieska',
  'Töysä',
  'Asikkala',
  'Askola',
  'Aura',
  'Brändö',
  'Eckerö',
  'Enonkoski',
  'Enontekiö',
  'Espoo',
  'Eura',
  'Luvia',
  'Evijärvi',
  'Finström',
  'Forssa',
  'Föglö',
  'Geta',
  'Haapajärvi',
  'Haapavesi',
  'Hailuoto',
  'Halsua',
  'Hamina',
  'Hammarland',
  'Hankasalmi',
  'Hanko',
  'Harjavalta',
  'Hartola',
  'Hattula',
  'Hausjärvi',
  'Heinola',
  'Heinävesi',
  'Helsinki',
  'Hirvensalmi',
  'Hollola',
  'Huittinen',
  'Humppila',
  'Hyrynsalmi',
  'Hyvinkää',
  'Hämeenkyrö',
  'Hämeenlinna',
  'Kuivaniemi',
  'Iisalmi',
  'Iitti',
  'Ikaalinen',
  'Ilmajoki',
  'Ilomantsi',
  'Imatra',
  'Inari',
  'Inkoo',
  'Isojoki',
  'Isokyrö',
  'Janakkala',
  'Joensuu',
  'Jokioinen',
  'Jomala',
  'Joroinen',
  'Joutsa',
  'Juuka', 
  'Juupajoki', 
  'Juva',  
  'Jyväskylä', 
  'Jämijärvi', 
  'Jämsänkoski',
  'Järvenpää',
  'Piikkiö',   
  'Kaavi', 
  'Kajaani',   
  'Himanka',   
  'Kangasala', 
  'Kangasniemi',
  'Kankaanpää',
  'Kannonkoski',
  'Kannus',
  'Karijoki',  
  'Karkkila',  
  'Karstula',  
  'Karvia',
  'Kaskinen',  
  'Kauhajoki', 
  'Kauhava',   
  'Kauniainen',
  'Kaustinen', 
  'Keitele',   
  'Kemi',  
  'Kemijärvi', 
  'Keminmaa',  
  'Dragsfjärd',
  'Kempele',   
  'Kerava',
  'Keuruu',
  'Kihniö',
  'Kinnula',
  'Kirkkonummi',
  'Kitee',
  'Kittilä',
  'Kiuruvesi',
  'Kivijärvi',
  'Kokemäki',
  'Kokkola',
  'Kolari',
  'Konnevesi',
  'Kontiolahti',
  'Korsnäs',
  'Koski',
  'Kotka',
  'Kouvola',
  'Kristiinankaupunki',
  'Kruunupyy',
  'Kuhmo',
  'Kuhmoinen',
  'Kumlinge',
  'Kuopio',
  'Kuortane',
  'Kurikka',
  'Kustavi',
  'Kuusamo',
  'Kyyjärvi',
  'Kärkölä',
  'Kärsämäki',
  'Kökar',
  'Lahti',
  'Laihia',
  'Laitila',
  'Lapinjärvi',
  'Lapinlahti',
  'Lappajärvi',
  'Lappeenranta',
  'Lapua',
  'Laukaa',
  'Lemi',
  'Lemland',
  'Lempäälä',
  'Leppävirta',
  'Lestijärvi',
  'Lieksa',
  'Lieto',
  'Liminka',
  'Liperi',
  'Lohja',
  'Loimaa',
  'Loppi',
  'Loviisa',
  'Luhanka',
  'Lumijoki',
  'Lumparland',
  'Luoto',
  'Luumäki',
  'Maalahti',
  'Maarianhamina',
  'Marttila',
  'Askainen',
  'Merijärvi',
  'Merikarvia',
  'Miehikkälä',
  'Mikkeli',
  'Muhos',
  'Multia',
  'Muonio',
  'Mustasaari',
  'Muurame',
  'Mynämäki',
  'Myrskylä',
  'Mäntsälä',
  'Vilppula',
  'Mäntyharju',
  'Naantali',
  'Nakkila',
  'Nivala',
  'Nokia',
  'Nousiainen',
  'Nurmes',
  'Nurmijärvi',
  'Närpiö',
  'Orimattila',
  'Oripää',
  'Orivesi',
  'Oulainen',
  'Oulu',
  'Outokumpu',
  'Padasjoki',
  'Paimio',
  'Paltamo',
  'Nagu',
  'Parikkala',
  'Parkano',
  'Pedersöre',
  'Pelkosenniemi',
  'Pellon',
  'Perho',
  'Pertunmaa',
  'Petäjävesi',
  'Pieksämäki',
  'Pielavesi',
  'Pietarsaari',
  'Pihtipudas',
  'Pirkkala',
  'Polvijärvi',
  'Pomarkku',
  'Pori',
  'Pornainen',
  'Porvoo',
  'Posio',
  'Pudasjärvi',
  'Pukkila',
  'Punkalaidun',
  'Puolanka',
  'Puumala',
  'Pyhtää',
  'Pyhäjoki',
  'Pyhäjärvi',
  'Pyhäntä',
  'Pyhäranta',
  'Pälkäne',
  'Pöytyä',
  'Raahe',
  'Raasepori',
  'Raisio',
  'Rantasalmi',
  'Ranua',
  'Rauma',
  'Rautalampi',
  'Rautavaara',
  'Rautjärvi',
  'Reisjärvi',
  'Riihimäki',
  'Ristijärvi',
  'Rovaniemi',
  'Ruokolahti',
  'Ruovesi',
  'Vahto',
  'Rääkkylä',
  'Saarijärvi',
  'Salla',
  'Halikko',
  'Saltvik',
  'Karkku',
  'Sauvo',
  'Savitaipale',
  'Savonlinna',
  'Savukoski',
  'Seinäjoki',
  'Sievi',
  'Siikainen',
  'Siikajoki',
  'Siikalatva',
  'Siilinjärvi',
  'Simo',
  'Sipoo',
  'Siuntio',
  'Sodankylä',
  'Soini',
  'Somero',
  'Sonkajärvi',
  'Sotkamo',
  'Sottunga',
  'Sulkava',
  'Sund',
  'Suomussalmi',
  'Suonenjoki',
  'Sysmä',
  'Köyliö',
  'Taipalsaari',
  'Taivalkoski',
  'Taivassalo',
  'Tammela',
  'Tampere',
  'Tervo',
  'Tervola',
  'Teuva',
  'Tohmajärvi',
  'Toholampi',
  'Toivakka',
  'Tornio',
  'Turku',
  'Tuusniemi',
  'Tuusula',
  'Tyrnävä',
  'Ulvila',
  'Urjala',
  'Utajärvi',
  'Utsjoki',
  'Uurainen',
  'Uusikaarlepyy',
  'Uusikaupunki',
  'Vaala',
  'Vaasa',
  'Valkeakoski',
  'Vantaa',
  'Varkaus',
  'Vehmaa',
  'Vesanto',
  'Vesilahti',
  'Veteli',
  'Vieremä',
  'Vihti',
  'Viitasaari',
  'Vimpeli',
  'Virolahti',
  'Virrat',
  'Vårdö',
  'Vöyri',
  'Ylitornio',
  'Ylivieska',
  'Ylöjärvi',
  'Ypäjä',
  'Ähtäri',
  'Äänekoski'
]