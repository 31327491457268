import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ItemVisibilitySelection from './ItemVisibilitySelection'
import Button from './Button'
import Chip from './Chip'
import { RoomSectionItemsMappings } from '../../Utils/room'
import { Colors } from '../../Utils/theme'

export default function RoomVisibleItemsModal(props: any) {
  const { onClose, loading, room } = props
  const { t } = useTranslation()

  const [floorHidden, setFloorHidden] = useState(false)
  const [ceilingHidden, setCeilingHidden] = useState(false)
  const [wall1Hidden, setWall1Hidden] = useState(false)
  const [wall2Hidden, setWall2Hidden] = useState(false)
  const [wall3Hidden, setWall3Hidden] = useState(false)
  const [wall4Hidden, setWall4Hidden] = useState(false)
  const [wall5Hidden, setWall5Hidden] = useState(false)
  const [wall6Hidden, setWall6Hidden] = useState(false)
  const [hiddenItems, setHiddenItems] = useState<any>({
    floor: [],
    ceiling: [],
    wall1: [],
    wall2: [],
    wall3: [],
    wall4: [],
    wall5: [],
    wall6: []
  })

  useEffect(() => {
    if (room) {
      setFloorHidden(room.floorHidden)
      setCeilingHidden(room.ceilingHidden)
      setWall1Hidden(room.wall1Hidden)
      setWall2Hidden(room.wall2Hidden)
      setWall3Hidden(room.wall3Hidden)
      setWall4Hidden(room.wall4Hidden)
      setWall5Hidden(room.wall5Hidden)
      setWall6Hidden(room.wall6Hidden)
      setHiddenItems({
        floor: room.hiddenItems?.['floor'] || [],
        ceiling: room.hiddenItems?.['ceiling'] || [],
        wall1: room.hiddenItems?.['wall1'] || [],
        wall2: room.hiddenItems?.['wall2'] || [],
        wall3: room.hiddenItems?.['wall3'] || [],
        wall4: room.hiddenItems?.['wall4'] || [],
        wall5: room.hiddenItems?.['wall5'] || RoomSectionItemsMappings['wall'],
        wall6: room.hiddenItems?.['wall6'] || RoomSectionItemsMappings['wall']
      })
    }
  }, [room])

  const onChange = (category: string, value: string) => {
    if (hiddenItems?.[category]?.includes(value)) {
      setHiddenItems({
        ...hiddenItems,
        [category]: hiddenItems[category].filter((item: string) => item !== value)
      })
    } else {
      setHiddenItems({
        ...hiddenItems,
        [category]: [...(hiddenItems?.[category] || []), value]
      })
    }
  }

  const toggleAllSectionItems = (section: keyof typeof RoomSectionItemsMappings, isHidden: boolean) => {
    if (!isHidden) {
      setHiddenItems({
        ...hiddenItems,
        [section]: []
      })
    } else {
      setHiddenItems({
        ...hiddenItems,
        [section]: RoomSectionItemsMappings[section?.includes('wall') ? 'wall' : section]
      })
    }
  }

  const save = () => {
    props.onSave({
      floorHidden,
      ceilingHidden,
      wall1Hidden,
      wall2Hidden,
      wall3Hidden,
      wall4Hidden,
      wall5Hidden,
      wall6Hidden,
      hiddenItems
    })
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip
        onClick={onClose}
        sx={styles.closeButton}
        text={t('close')}
      />
      <DialogTitle variant='h2' sx={styles.title}>{t('visible_sections')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <DialogContentText variant='body2' sx={styles.contentText}>
          {t(`${props?.type || 'room'}_visible_items_info`)}
        </DialogContentText>
        <ItemVisibilitySelection
          section='floor'
          sectionHidden={floorHidden}
          onSectionHiddenChange={setFloorHidden}
          items={RoomSectionItemsMappings.floor}
          hiddenItems={hiddenItems?.['floor'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='ceiling'
          sectionHidden={ceilingHidden}
          onSectionHiddenChange={setCeilingHidden}
          items={RoomSectionItemsMappings.ceiling}
          hiddenItems={hiddenItems?.['ceiling'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall1'
          sectionHidden={wall1Hidden}
          onSectionHiddenChange={setWall1Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall1'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall2'
          sectionHidden={wall2Hidden}
          onSectionHiddenChange={setWall2Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall2'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall3'
          sectionHidden={wall3Hidden}
          onSectionHiddenChange={setWall3Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall3'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall4'
          sectionHidden={wall4Hidden}
          onSectionHiddenChange={setWall4Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall4'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall5'
          sectionHidden={wall5Hidden}
          onSectionHiddenChange={setWall5Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall5'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
        <ItemVisibilitySelection
          section='wall6'
          sectionHidden={wall6Hidden}
          onSectionHiddenChange={setWall6Hidden}
          items={RoomSectionItemsMappings.wall}
          hiddenItems={hiddenItems?.['wall6'] || []}
          onItemHiddenChange={onChange}
          toggleAllSectionItems={toggleAllSectionItems}
        />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={t('cancel')}
          onClick={onClose}
          variant='secondary'
          width='11rem'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={save}
          loading={loading}
          disabled={loading}
          width='11rem'
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '50rem',
      maxWidth: '50rem',
      maxHeight: '80vh',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    fontSize: '1rem',
    marginBottom: '1.5rem'
  },
  button: {
    height: '3.125rem',
    pl: '2.5rem',
    pr: '2.5rem',
    mr: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: 0,
    marginTop: '1rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  outlinedButton: {
    height: '3.125rem',
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    },
    ml: '0.75rem'
  }
} as const
