import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import Collapse from '@mui/material/Collapse'
import Button from '../Common/Button'
import ArrowDown from '../../Assets/Icons/arrow-down-primary.svg'
import { Colors } from '../../Utils/theme'

export default function SidebarFormSettings(props: any) {
  const { t } = useTranslation()

  const { updatedAt, isOpen } = props

  const toggleOpen = () => props.onToggleOpen()
  const onViewVisibleFields = () => props.onView()

  const UpdatedAt = useMemo(() => {
    let date = updatedAt || ''
    if (date) {
      const luxonDate = DateTime.fromISO(date)
      date = `${luxonDate.setLocale('fi').toFormat('ccc')} ${luxonDate.toFormat('dd.MM.yyyy')} ${t('at')} ${luxonDate.toFormat('HH.mm')}`
    }
    return date || '-'
  }, [updatedAt])

  return (
    <Box sx={styles.container} mt='1.5rem'>
      <Box sx={styles.actionsHeader}>
        <Typography variant='h5' sx={styles.title}>
          {t('forms')}
        </Typography>
        <ButtonBase onClick={toggleOpen}>
          <Box
            component='img'
            src={ArrowDown}
            alt='arrow-down'
            sx={{ ...styles.arrowIcon, transform: `scaleY(${isOpen ? 1 : -1})` }}
          />
        </ButtonBase>
      </Box>
      <Collapse in={isOpen}>
        <Box sx={styles.contentContainer}>
          <Box sx={styles.actionInfo}>
            <Typography variant='body1' sx={styles.label}>
              {t('last_updated_at')}:
            </Typography>
            <Typography variant='body1' color={Colors.text}>
              {UpdatedAt}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.footer}>
          <Button
            text={t('visible_sections')}
            onClick={onViewVisibleFields}
            width='100%'
          />
        </Box>
      </Collapse>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    maxWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    // p: '2rem 2rem 0.5rem 2rem',
    mb: '1.5rem'
  },
  contentContainer: {
    p: {
      lgplus: '0rem 1.5rem 1.5rem 1.5rem',
      xs: '0rem 1.125rem 1.125rem 1.125rem'
    }
  },
  title: {
    fontSize: {
      lgplus: '1.25rem',
      xs: '1rem'
    },
    fontWeight: 800,
    alignSelf: 'flex-start',
    mb: '1rem',
    textTransform: 'uppercase'
  },
  actionInfo: {

  },
  label: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primary,
    mb: '0.0625rem'
  },
  footer: {
    padding: '1rem',
    backgroundColor: Colors.secondary10,
    borderTop: `1px solid ${Colors.secondary}` 
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '0.375rem'
  },
  actionItemColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  archiveButton: {
    height: {
      lgplus: '2.625rem',
      xs: '2.375rem'
    },
    fontSize: {
      lgplus: '0.925rem',
      xs: '0.825rem'
    },
    fontWeight: 600,
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    borderRadius: {
      lgplus: '0.625rem',
      xs: '0.5rem'
    },
    p: {
      lgplus: '0.5rem 1.5rem',
      xs: '0.5rem 1rem'
    },
    mb: '0.625rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  arrowIcon: {
    width: {
      lgplus: '1.25rem',
      xs: '1rem'
    },
    height: {
      lgplus: '1.25rem',
      xs: '1rem'
    },
    mb: '1rem'
  },
  actionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '1.5rem 1.5rem 0.5rem 1.5rem'
  }
} as const