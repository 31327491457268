export enum ComponentTypes {
  Title = 'title',
  Subtitle = 'subtitle',
  VerticalSpacer = 'vertical_spacer',
  HorizontalSpacer = 'horizontal_spacer',
  Input = 'input',
  MultilineInput = 'multiline_input',
  NumberInput = 'number_input',
  DateInput = 'date_input',
  TimeInput = 'time_input',
  Select = 'select',
  NumberSelect = 'number_select',
  MultiSelect = 'multi_select',
  Checkbox = 'checkbox',
  BooleanCheckbox = 'boolean_checkbox',
  Radio = 'radio',
  Boolean = 'boolean',
  Row = 'row',
  Divider = 'divider',
}

export const Title = { type: ComponentTypes.Title }
export const Subtitle = (text: string) => ({ type: ComponentTypes.Subtitle, label: text })
export const Input = { type: ComponentTypes.Input }
export const MultilineInput = { type: ComponentTypes.MultilineInput }
export const Select = { type: ComponentTypes.Select }
export const MultiSelect = { type: ComponentTypes.MultiSelect }
export const Checkbox = { type: ComponentTypes.Checkbox }
export const Row = { type: ComponentTypes.Row }
export const Divider = { type: ComponentTypes.Divider}
export const HorizontalSpacer = { type: ComponentTypes.HorizontalSpacer }

export const DefaultOptions = [
  { value: '1', label: 'Valinta 1' },
  { value: '2', label: 'Valinta 2' },
  { value: '3', label: 'Valinta 3' }
]

export const BooleanOptions = [
  { value: 'yes', label: 'Kyllä' },
  { value: 'no', label: 'Ei' }
]

export const Option = (value: string, label?: string) => {
  if (!label) label = value
  return { value, label }
}
export const OtherOption = { value: 'other', label: 'other' }
export const IfOtherSelected = (key: string) => ({ key, values: ['other'] })
export const UnknownOption = { value: 'unknown', label: 'unknown' }
export const IfUnknownSelected = (key: string) => ({ key, values: ['unknown'] })
export const IfCustomSelected = (key: string, value?: string) => ({ key, values: [value ?? key] })
export const IfYesSelected = (key: string) => ({ key, values: ['true', 'yes', true] })
export const IfNoSelected = (key: string) => ({ key, values: ['false', 'no', false, null, undefined] })

export const OtherPlaceholder = { placeholder: 'enter_more_specific_description' }
export const UnknownPlaceholder = { placeholder: 'recommendation' }

export const YearOptions = (start?: number, end?: number) => {
  if (!start) start = new Date().getFullYear()
  if (!end) end = start - 100
  const options = []
  for (let i = start; i >= end; i--) {
    options.push({ value: i.toString(), label: i.toString() })
  }
  return options
}

export const CountOptions = (from: number, to: number) => {
  if (from > to) return []
  const options = []
  for (let i = from; i <= to; i++) {
    options.push({ value: i.toString(), label: i.toString() })
  }
  return options
}

export enum ComponentExtraProperties {
  DisabledIfNoParentValue = 'disabled_if_no_parent_value',
  DisabledIfParentValue = 'disabled_if_parent_value'
}

export enum ComponentExtraStyles {
  Indented = 'indented',
  Row = 'row'
}

export enum LayoutPosition {
  Left = 'left',
  Right = 'right',
  Full = 'full'
}

export enum FormTypes {
  SectionSubsections = 'section_subsections'
}