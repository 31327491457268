import { t } from 'i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Chip } from '..'
import { Button } from '..'
import { Colors } from '../../Utils/theme'

export default function ConfirmationDialog(props: any) {
  const {
    title,
    content,
    onClose,
    onCancel,
    cancelText,
    onAction,
    actionText,
    loading
  } = props

  const getDialogStyles = () => {
    if (props?.width) {
      return {
        ...styles.dialog,
        '.MuiDialog-paper': {
          minWidth: props.width,
          width: props.width,
          borderRadius: '0.625rem',
          padding: '1.5rem 1.5rem'
        }
      }
    }
    return styles.dialog
  }

  const renderChipButton = () => {
    if (props?.chipClose) {
      return (
        <Chip
          onClick={props?.onChipAction || onClose}
          sx={styles.chipButton}
          text={props?.chipActionText || t('cancel')}
        />
      )
    }
    return null
  }

  return (
    <Dialog sx={getDialogStyles()} onClose={onClose} open>
      {renderChipButton()}
      <DialogTitle
        variant='h2'
        sx={styles.title}
        mt={props?.chipClose ? '0.75rem' : '0rem'}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <DialogContentText variant='body1' sx={styles.contentText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={cancelText || t('cancel')}
          onClick={onCancel || onClose}
          width={props?.cancelButtonWidth || '12rem'}
          fontSize={props?.buttonFontSize || undefined}
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={actionText || t('save')}
          onClick={onAction}
          loading={loading}
          disabled={loading}
          width={props?.actionButtonWidth || '12rem'}
          fontSize={props?.buttonFontSize || undefined}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '31rem',
      width: '31rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '4rem'
  },
  title: {
    color: Colors.heading,
    alignSelf: 'center',
    textAlign: 'center'
  },
  content: {
    alignSelf: 'center',
    marginBottom: '0.5rem'
  },
  contentText: {
    textAlign: 'center'
  },
  outlinedButton: {
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  chipButton: {
    position: 'absolute',
    top: '0rem',
    right: '0rem'
  },
} as const
