import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import CloseIcon from '../../Assets/Icons/close.svg'
import VisibleIcon from '../../Assets/Icons/visible.svg'
import HiddenIcon from '../../Assets/Icons/hidden.svg'
import CalendarIcon from '../../Assets/Icons/calendar.svg'
import SearchIcon from '../../Assets/Icons/search-alt.svg'
import Button from './Button'
import ColorPicker from './ColorPicker'
import Chip from './Chip'
import i18n from '../../Localization'
import { Colors } from '../../Utils/theme'
import { showMessage } from '../../Utils/message'

export default function Input(props: any) {
  const {
    id,
    label,
    value,
    placeholder,
    type,
    multiline,
    inputSx,
    autoFocus,
    handleKeyPress,
    disabled,
    onAction
  } = props

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getInputStyle = () => {
    let inputStyles: any = multiline ? styles.multiline : styles.input
    if (inputSx) inputStyles = { ...inputStyles, ...inputSx }
    if (props?.inputMinHeight) inputStyles.minHeight = props.inputMinHeight
    if (props?.auth) {
      inputStyles = { ...inputStyles, ...styles.authInput }
    }
    if (props?.unit) {
      inputStyles = { ...inputStyles, paddingRight: '3.125rem' }
    }
    if (props?.colorPicker) {
      inputStyles = { ...inputStyles, paddingLeft: '2.25rem' }
    }
    if (props?.invalid) {
      inputStyles = { ...inputStyles, border: `2px solid ${Colors.red}` }
    }
    if (props?.search) {
      inputStyles = { ...inputStyles, paddingLeft: '2.25rem' }
    }
    return inputStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props?.iconSx) iconStyles = { ...iconStyles, ...props.iconSx }
    return iconStyles
  }
  
  const onChange = (evt: any) => {
    props.onChange(evt.target.value)
    if (props?.setUnsavedChanges) {
      props.setUnsavedChanges(true)
    }
  }
  const onColorChange = (color: string) => props.onChange(color)
  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(value)
    showMessage(i18n.t('copied_to_clipboard'))
  }

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel
          sx={{ ...styles.label, fontWeight: props?.labelFontWeight || 600 }}
          htmlFor={id}
          disableAnimation
        >
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderCustomValue = () => {
    if (props?.valueAsChip) {
      return <Chip text={value} sx={styles.chipValue} />
    }
    return null
  }

  const renderIcon = () => {
    if (props?.search) {
      return <Box component='img' src={SearchIcon} sx={styles.searchIcon} />
    }
    return null
  }

  const renderActionButton = () => {
    if (props?.copy) {
      return (
        <Button
          onClick={onCopyToClipboard}
          icon='copy'
          sx={styles.iconButton}
          iconSx={styles.iconButtonIcon}
        />
      )
    }
    if (onAction && value?.length) {
      if (props?.password) {
        return (
          <Box
            component='img'
            sx={getIconStyle()}
            src={type === 'password' ? VisibleIcon : HiddenIcon}
            onClick={onAction}
          />
        )
      }
      return <Box component='img' sx={getIconStyle()} src={CloseIcon} onClick={onAction} />
    }
    if (props?.unit) {
      return (
        <Box sx={!props?.label ? styles.unitAltContainer : styles.unitContainer}>
          <Typography variant='body1' sx={styles.unit}>{props.unit}</Typography>
        </Box>
      )
    }
    if (type === 'date') {
      return (
        <Box sx={!props?.label ? styles.unitAltContainer : styles.unitContainer}>
          <Box component='img' sx={styles.dateIcon} src={CalendarIcon} onClick={() => {}} />
        </Box>
      )
    }
    if (props?.colorPicker) {
      return <ColorPicker value={value} onChange={onColorChange} />
    }
    return null
  }

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      {renderCustomValue()}
      {renderIcon()}
      <TextField
        id={id}
        value={!props?.valueAsChip ? (value ?? '') : ''}
        onChange={onChange}
        onBlur={props?.onBlur || (() => {})}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        type={type}
        multiline={multiline}
        autoFocus={autoFocus}
        disabled={disabled}
        // sx={sx}
        InputProps={{
          sx: getInputStyle(),
          ...(multiline ? { rows: 5, multiline: true, inputComponent: 'textarea' } : {})
        }}
      />
      {renderActionButton()}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    height: '3.125rem',
    fontSize: '1.125rem',
    color: Colors.text,
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    borderColor: Colors.border,
    padding: '0rem 0.125rem',
    border: `0.0625rem solid ${Colors.border}`,
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    }
  },
  authInput: {
    backgroundColor: Colors.white,
    alignSelf: 'center',
    width: '100%',
    height: '4rem',
    color: Colors.text,
    mb: '1.375rem',
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    }
  },
  multiline: {
    minHeight: '8rem',
    fontSize: '1rem',
    color: '#000000',
    backgroundColor: Colors.white,
    borderRadius: '5px',
    borderColor: Colors.text,
    padding: '1rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    border: `0.0625rem solid ${Colors.border}`,
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    }
  },
  icon: {
    position: 'absolute',
    top: '1rem',
    right: '1.375rem',
    cursor: 'pointer',
    width: '1.875rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  },
  iconButton: {
    position: 'absolute',
    top: '2.375rem',
    right: '0.5rem',
    height: '2.25rem',
    minWidth: '2.5rem',
    padding: 0
  },
  iconButtonIcon: {
    width: '1.25rem',
    marginRight: '0rem'
  },
  searchIcon: {
    position: 'absolute',
    top: '0.75rem',
    left: '0.75rem',
    height: '1.625rem',
    minWidth: '1.625rem',
    zIndex: 1
  },
  unitContainer: {
    position: 'absolute',
    top: '2.0625rem',
    right: '2px',
    height: 'calc(3.125rem - 0.25rem)',
    width: '3.125rem',
    backgroundColor: Colors.grayBg,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `0.0625rem solid ${Colors.border}`,
    padding: 0
  },
  unitAltContainer: {
    position: 'absolute',
    top: '0.125rem',
    right: '2px',
    height: 'calc(3.125rem - 0.25rem)',
    width: '3.125rem',
    backgroundColor: Colors.grayBg,
    borderTopRightRadius: '0.3125rem',
    borderBottomRightRadius: '0.3125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `0.0625rem solid ${Colors.border}`,
    padding: 0
  },
  unit: {
    color: Colors.text,
    fontSize: '1.125rem',
    fontWeight: 400
  },
  dateIcon: {
    position: 'absolute',
    cursor: 'pointer',
    width: '1.5rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  },
  chipValue: {
    position: 'absolute',
    top: '2.625rem',
    left: '0.75rem',
    zIndex: 9999999999
  }
} as const
