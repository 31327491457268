import { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Timeline from '../../../Components/Common/Timeline'
import ProjectModal from '../../../Components/Common/ProjectModal'
import { Button, Select } from '../../../Components'
import { ProjectTypes } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const Projects = () => {
  const { propertyStore, projectStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const timelineRef: any = useRef(null)
  const maxItemsRef: any = useRef(null)
  const refMap: any = {
    2014: useRef(null),
    2015: useRef(null),
    2016: useRef(null),
    2017: useRef(null),
    2018: useRef(null),
    2019: useRef(null),
    2020: useRef(null),
    2021: useRef(null),
    2022: useRef(null),
    2023: useRef(null),
    2024: useRef(null),
    2025: useRef(null),
    2026: useRef(null),
    2027: useRef(null),
    2028: useRef(null),
    2029: useRef(null),
    2030: useRef(null)
  }
  const projectsMap: any = useRef({
    2014: [],
    2015: [],
    2016: [],
    2017: [],
    2018: [],
    2019: [],
    2020: [],
    2021: [],
    2022: [],
    2023: [],
    2024: [],
    2025: [],
    2026: [],
    2027: [],
    2028: [],
    2029: [],
    2030: []
  })

  const { property } = propertyStore
  const { tab, projects } = projectStore

  const [type, setType] = useState<string | null>(null)
  const [budget, setBudget] = useState<string | null>(null)
  const [project, setProject] = useState<any>(null)
  const [layoutStyle, setLayoutStyle] = useState('default')

  const openProject = (project: any) => {
    setProject(project)
    const callback = (proj: any) => setProject(proj)
    projectStore.getProject(params?.uuid, project.uuid, callback)
  }
  const closeModal = () => setProject(null)

  useEffect(() => {
    if (tab === 'costs') {
      // navigate(`/properties/${params.uuid}/costs`)
    }
  }, [tab])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
      projectStore.setTab('projects')
    }
  }, [])

  useEffect(() => {
    if (property) {
      const offset = refMap?.[DateTime.now().year - 2]?.current?.offsetLeft || 0
      timelineRef?.current?.scrollTo(offset - 37, 0, 'smooth')
      projectStore.getProjects(property.uuid)
    }
  }, [property])

  useEffect(() => {
    if (projects) {
      for (const project of projects) {
        const years = []
        for (let i = DateTime.fromISO(project.startTime).year; i <= DateTime.fromISO(project.endTime).year; i++) {
          years.push(i)
        }
        for (const year of years) {
          if (!projectsMap.current?.[year]?.includes(project.uuid)) {
            projectsMap.current[year] = [
              ...projectsMap.current?.[year] || [],
              project.uuid
            ]
          }
        }
      }
    }
  }, [projects])

  const ProjectTypeOptions = Object.values(ProjectTypes).map((option: any) => ({ 
    value: option,
    label: t(option)
  }))
  const BudgetOptions = [
    { value: '0-10000', label: '0 - 10 000 €' },
    { value: '10000-50000', label: '10 000 - 50 000 €' },
    { value: '50000-100000', label: '50 000 - 100 000 €' },
    { value: '100000-250000', label: '100 0000 - 250 000 €' },
    { value: '250000-500000', label: '250 000 - 500 000 €' },
    { value: '500000-1000000', label: '500 000 - 1 000 000 €' },
    { value: '1000000-100000000', label: '> 1 000 000 €' }
  ]
  const LayoutStyleOptions = [
    { value: 'default', label: t('default_ui') },
    { value: 'dense', label: t('dense_ui') }
  ]
  
  const getProjectsByYear = useCallback((year: number) => {
    if (projects) {
      // Filter projects by year
      let filteredProjects = projects.filter((project: any) => {
        const start = DateTime.fromISO(project.startTime)
        const end = DateTime.fromISO(project.endTime)
        return start.year <= year && end.year >= year
      })
      // Filter projects by type
      if (type) {
        filteredProjects = filteredProjects.filter((project: any) => project.type === type)
      }
      // Filter projects by budget
      if (budget) {
        const parts = budget.split('-')
        const min = parseInt(parts[0])
        const max = parseInt(parts[1])
        filteredProjects = filteredProjects.filter((project: any) => {
          return project.totalCost >= min && project.totalCost <= max
        })
      }
      if (filteredProjects?.length > maxItemsRef.current) {
        maxItemsRef.current = filteredProjects.length
      }
      const previousYearProjects = projectsMap?.current?.[year - 1] ?? null
      if (previousYearProjects && previousYearProjects?.length) {
        const sortedArray = Array.from(
          { 
            length: Math.max(previousYearProjects?.length, filteredProjects?.length),
            // length: maxItemsRef.current,
          },
          () => null
        )
        for (let i = 0; i < previousYearProjects?.length; i++) {
          const project = filteredProjects.find((proj: any) => proj.uuid === previousYearProjects[i])
          if (project) {
            sortedArray[i] = project
          }
        }
        for (let i = 0; i < filteredProjects?.length; i++) {
          if (!sortedArray.find((proj: any) => proj?.uuid === filteredProjects[i]?.uuid)) {
            let index = sortedArray.indexOf(null)
            if (index === -1) {
              index = sortedArray.length
            }
            sortedArray[index] = filteredProjects[i]
          }
        }
        projectsMap.current[year] = sortedArray.map((project: any) => project?.uuid || null)
        filteredProjects = sortedArray
      }
      return filteredProjects
    }
    return []
  }, [projects, type, budget])

  const toAddProject = () => navigate(`${location.pathname}/create`)

  const updateProjectBackgroundPhoto = (file: any) => {
    projectStore.updateProjectBackgroundPhoto(params?.uuid, file?.uuid)
  }

  const downloadFile = async (file: any) => {
    try {
      const link = document.createElement('a')
      link.href = file.url
      link.setAttribute('download', file?.name || file?.originalName)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  const archiveFile = (file: any) => projectStore.archiveProjectFile(params?.uuid, file?.uuid)

  const createComment = (comment: string, callback: any) => {
    const cback = (proj: any) => {
      setProject(proj)
      if (callback) {
        callback()
      }
    }
    projectStore.createProjectComment(
      params?.uuid,
      project?.uuid,
      comment,
      cback
    )
  }

  const updateComment = (cUuid: string, comment: any, callback: any) => {
    const cback = (proj: any) => {
      setProject(proj)
      if (callback) {
        callback()
      }
    }
    projectStore.updateProjectComment(
      params?.uuid,
      project?.uuid,
      cUuid,
      comment,
      cback
    )
  }

  const archiveComment = (cUuid: string) => {
    const cback = (proj: any) => {
      setProject(proj)
    }
    projectStore.archiveProjectComment(
      params?.uuid,
      project?.uuid,
      cUuid,
      cback
    )
  }

  const ProjectTimeline = useMemo(() => {
    const years = []
    const start = Math.min(
      2018,
      projects.map((project: any) => DateTime.fromISO(project.startTime).year)
      .reduce((a: number, b: number) => Math.min(a, b), 2021)
    )
    const end = Math.max(
      2030,
      projects.map((project: any) => DateTime.fromISO(project.endTime).year)
      .reduce((a: number, b: number) => Math.max(a, b), 2021)
    )
    for (let i = start; i <= end; i++) {
      years.push(i)
    }
    return years.map((year: number) => {
      return (
        <Timeline
          key={year}
          ref={refMap[year]}
          year={year}
          projects={getProjectsByYear(year)}
          maxItems={maxItemsRef.current}
          onClick={openProject}
          layoutStyle={layoutStyle}
        />
      )
    })
  }, [projects, type, budget, layoutStyle])

  const renderProjectModal = () => {
    if (project) {
      return (
        <ProjectModal
          project={project}
          onClose={closeModal}
          updateProjectBackgroundPhoto={updateProjectBackgroundPhoto}
          downloadFile={downloadFile}
          archiveFile={archiveFile}
          createComment={createComment}
          updateComment={updateComment}
          archiveComment={archiveComment}
        />
      )
    }
    return null
  }

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.actions}>
        <Box sx={styles.row}>
          <Select
            options={ProjectTypeOptions}
            value={type}
            onChange={setType}
            placeholder={t('action_type')}
            sx={styles.sortSelect}
            width='17.5rem'
            mr='1.5rem'
          />
          <Select
            options={BudgetOptions}
            value={budget}
            onChange={setBudget}
            placeholder={t('cost_range')}
            sx={styles.sortSelect}
            width='17.5rem'
          />
          <Select
            options={LayoutStyleOptions}
            value={layoutStyle}
            onChange={setLayoutStyle}
            placeholder={t('layout_style')}
            sx={styles.sortSelect}
            width='15.5rem'
            ml='1.5rem'
            disableClearable
          />
        </Box>
        <Box sx={styles.actionsRow}>
          <Button
            text={t('add_new')}
            onClick={toAddProject}
            sx={styles.actionButton}
            width='11rem'
            ml='1.5rem'
          />
        </Box>
      </Box>
      <Box ref={timelineRef} sx={styles.timelineContainer}>
        {ProjectTimeline}
      </Box>
      {renderProjectModal()}
    </Box>
  )
}

export default observer(Projects)

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '0.75rem',
    pb: '4rem',
    overflow: 'hidden'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    mb: '1.25rem',
    border: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  sortSelect: {
    width: {
      xxl: '20rem',
      xl: '18rem',
      xs: '14rem'
    }
  },
  actionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    }
  },
  timelineContainer: {
    minHeight: 'calc(100vh - 13rem)',
    // maxHeight: 'calc(100vh - 13rem)',
    maxWidth: 'calc(100vw - 20rem)',
    // overflowY: 'auto',
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1.5rem',
    scrollBehavior: 'smooth'
  }
} as const
