import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from './Button'
import Chip from './Chip'
import Input from './Input'
import { Colors } from '../../Utils/theme'
import MultiSelect from './MultiSelect'
import { RoomTypes } from '../../Utils/room'

export default function UpdateFileModal(props: any) {
  const { onClose, loading, onSave, file } = props
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (file) {
      setName(file?.name || file?.originalName)
      if (file?.tags?.length) {
        setTags(file?.tags?.split(',').map((tag: string) => tag.trim()) || [])
      }
    }
  }, [file])

  const TagOptions = Object.values(RoomTypes)
    .filter((type: any) => type !== RoomTypes.Other)
    .map((type: any) => ({ value: type, label: t(type) }))

  const updateFile = () => onSave({
    name,
    tags: tags.join(',')
  })

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip
        onClick={onClose}
        sx={styles.closeButton}
        text={t('close')}
      />
      <DialogTitle variant='h2' sx={styles.title}>{t('update_file')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Input
          label={t('name')}
          value={name}
          onChange={setName}
          mb='1.25rem'
        />
        {props?.entity === 'apartment' ? (
          <MultiSelect
            options={TagOptions}
            value={tags}
            onChange={setTags}
            label={t('keywords_or_categories')}
            mb='1rem'
          />
        ) : null}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={t('cancel')}
          onClick={onClose}
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={updateFile}
          loading={loading}
          disabled={loading || !name}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  outlinedButton: {
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem',
    minWidth: '10rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: 'calc(100% - 1.5rem)',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
