import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from './Checkbox'
import { Colors } from '../../Utils/theme'

export default function ItemVisibilitySelection(props: any) {
  const { t } = useTranslation()

  const { section, sectionHidden, items, hiddenItems } = props

  const onSectionHiddenChange = () => {
    const isHidden = !sectionHidden
    props.onSectionHiddenChange(isHidden)
    props.toggleAllSectionItems(section, isHidden)
  }
  const onItemHiddenChange = (item: string) => props.onItemHiddenChange(section, item)

  return (
    <Box sx={styles.container}>
      <Checkbox
        label={section ? t(section) : ''}
        checked={!sectionHidden}
        onChange={onSectionHiddenChange}
        labelSx={styles.categoryLabel}
        size='1.375rem'
        iconSize='1rem'
        mb='1rem'
      />
      <Box sx={styles.optionsContainer}>
        {
          items.map((item: string) => {
            return (
              <Checkbox
                key={item}
                label={t(item)}
                checked={!hiddenItems?.includes(item)}
                onChange={() => onItemHiddenChange(item)}
                labelSx={styles.optionLabel}
                disabled={sectionHidden}
                mr='1.25rem'
                mb='1rem'
              />
            )
          })
        }
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    backgroundColor: Colors.secondary10,
    p: '1.375rem 1.375rem 0.375rem 1.375rem',
    mb: '1.5rem'
  },
  categoryLabel: {
    fontWeight: 600,
    fontSize: '1.375rem',
    color: Colors.primary
  },
  optionLabel: {
    fontWeight: 600,
    marginRight: '1.5rem'
  },
} as const