import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class RoomStore {
  rootStore

  rooms: any = []
  room: any = null
  floor: any = null
  ceiling: any = null
  wall: any = null
  files: any = []
  hasUnsavedChanges: boolean = false
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setRooms = (rooms: any) => { this.rooms = rooms }
  setRoom = (room: any) => { this.room = room }
  setFloor = (floor: any) => { this.floor = floor }
  setCeiling = (ceiling: any) => { this.ceiling = ceiling }
  setWall = (wall: any) => { this.wall = wall }
  setFiles = (files: any) => { this.files = files }
  setHasUnsavedChanges = (hasUnsavedChanges: boolean) => { this.hasUnsavedChanges = hasUnsavedChanges }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setRooms([])
    this.setRoom(null)
    this.setFloor(null)
    this.setCeiling(null)
    this.setWall(null)
    this.setFiles([])
    this.setLoading(false)
  }

  async getRooms(pUuid: string, aUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRooms(pUuid, aUuid)
      if (response?.ok) {
        const rooms = response.data?.items || []
        this.setRooms(rooms)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoom(pUuid: string, aUuid: string, rUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoom(pUuid, aUuid, rUuid)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoomFloor(pUuid: string, aUuid: string, rUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoomFloor(pUuid, aUuid, rUuid)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoomCeiling(pUuid: string, aUuid: string, rUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoomCeiling(pUuid, aUuid, rUuid)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoomWall(pUuid: string, aUuid: string, rUuid: string, wallNumber: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoomWall(pUuid, aUuid, rUuid, wallNumber)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
        this.setWall(room?.[`wall${wallNumber}`])
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createRoom(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createRoom(pUuid, aUuid, payload)
      if (response?.ok) {
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoom(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoom(pUuid, aUuid, this.room.uuid, payload)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomFloor(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomFloor(pUuid, aUuid, this.room.uuid, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setRoom(data)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomCeiling(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomCeiling(pUuid, aUuid, this.room.uuid, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setRoom(data)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomWall(pUuid: string, aUuid: string, wallNumber: number, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomWall(pUuid, aUuid, this.room.uuid, wallNumber, payload)
      if (response?.ok) {
        const data = response?.data || null
        this.setRoom(data)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomBackgroundPhoto(pUuid: string, aUuid: string, file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomBackgroundPhoto(pUuid, aUuid, this.room.uuid, file)
      if (response?.ok) {
        const data = response?.data || null
        this.setRoom(data)
        showMessage(i18n.t('room_updated'))
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveRoom(pUuid: string, aUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveRoom(pUuid, aUuid, this.room.uuid)
      if (response?.ok) {
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomSettings(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomSettings(pUuid, aUuid, this.room.uuid, payload)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
