import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import ArrowIcon from '../../Assets/Icons/dropdown-arrow.svg'
import { Colors } from '../../Utils/theme'

export default function Select(props: any) {
  const { id, value, label, placeholder, options } = props
  const { t } = useTranslation()

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getLabel = (option: any) => options?.find((o: any) => {
    return o.value === (option?.value || option)
  })?.label ?? ''
  const isOptionEqualToValue = (option: any, value: any) => option?.value === value

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.label,
  })

  const onChange = (event: any, newValue: any) => {
    props.onChange(newValue?.value)
    if (props?.setUnsavedChanges) {
      props.setUnsavedChanges(true)
    }
  }

  const renderArrowIcon = () => <Box component='img' src={ArrowIcon} alt='icon' />

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel sx={{ ...styles.label, fontWeight: props?.labelFontWeight || 600 }} htmlFor={id} disableAnimation>
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      name='noAutoFill'
      placeholder={placeholder}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'off',
        sx: styles.input
      }}
    />
  )

  const renderValueAsBadge = () => {
    if (props?.valueAsBadge) {
      const option = options?.find((o: any) => o.value === value)
      if (option) {
        return (
          <Box sx={{
            ...styles.badge,
            backgroundColor: props?.badgeColor || Colors.primary,
            left: props?.badgeLeftOffset || 0,
            color: Colors.white
          }}>
            {option.label}
          </Box>
        )
      }
    }
  }

  const renderOption = (props: any, option: any) => {
    return (
      <Box component='li' sx={styles.option} {...props}>
        {option.label}
      </Box>
    )
  }

  const selectedOption = options?.find((o: any) => o.value === value) || null

  const renderContent = () => {
    if (props?.valueAsBadge) {
      return (
        <>
          {renderValueAsBadge()}
          <Autocomplete
            id={id}
            value={value}
            inputValue={renderValueAsBadge() ? '' : getLabel(value)}
            onChange={onChange}
            options={options}
            getOptionLabel={option => option?.label || ''}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={renderOption}
            renderInput={renderInput}
            sx={styles.container}
            disabled={props?.disabled}
            popupIcon={!props?.disabled ? renderArrowIcon() : null}
            openText={t('open')}
            closeText={t('close')}
            clearText={t('clear')}
            noOptionsText={t('no_options')}
            filterOptions={filterOptions}
            disableClearable={!!props?.disableClearable}
            autoHighlight
          />
        </>
      )
    }
    return (
      <Autocomplete
        id={id}
        value={selectedOption}
        onChange={onChange}
        options={options}
        getOptionLabel={option => option?.label || ''}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOption}
        renderInput={renderInput}
        sx={styles.container}
        disabled={props?.disabled}
        popupIcon={!props?.disabled ? renderArrowIcon() : null}
        openText={t('open')}
        closeText={t('close')}
        clearText={t('clear')}
        noOptionsText={t('no_options')}
        filterOptions={filterOptions}
        disableClearable={!!props?.disableClearable}
        autoHighlight
      />
    )
  }

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      {renderContent()}
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    '& .MuiAutocomplete-inputRoot': {
      p: '0rem 0.6875rem',
      height: '3.125rem',
      borderRadius: '0.3125rem',
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.border}`,
      '&:hover': {
        borderColor: Colors.border
      },
      "&.Mui-focused": {
        border: `0.0625rem solid ${Colors.border}`,
      }
    },
    '& .MuiAutocomplete-popupIndicator': {
      padding: '0.5rem'
    }
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    fontSize: '1.125rem',
    color: Colors.text,
    borderRadius: '0.3125rem',
    border: 'none',
    '&::placeholder': {
      color: Colors.text
    }
  },
  option: {
    fontSize: '1rem',
    color: Colors.text
  },
  badge: {
    position: 'absolute',
    top: '0.625rem',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.3125rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    zIndex: 1
  }
} as const