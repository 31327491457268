import { useCallback, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from './ConfirmationDialog'

interface NavigationGuardProps {
  hasUnsavedChanges: boolean
  forceShow?: any
  onHide?: (to?: any) => void
  onSave?: () => void
}

export default function NavigationGuard({ hasUnsavedChanges, forceShow, onHide, onSave }: NavigationGuardProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const listenerCleanupRef = useRef<(() => void) | null>(null)
  const intendedPathRef = useRef<string | null>(null)

  const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    if (!hasUnsavedChanges) {
      setShowConfirmation(false)
      return
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault()
      intendedPathRef.current = document.referrer || '/'
      window.history.pushState(null, '', location.pathname)
      setShowConfirmation(true)
    }

    // Initial history state
    window.history.pushState(null, '', location.pathname)

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    // Store cleanup function
    listenerCleanupRef.current = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopState)
    }

    return () => {
      if (listenerCleanupRef.current) {
        listenerCleanupRef.current()
      }
    }
  }, [hasUnsavedChanges, location.pathname])

  const handleConfirmNavigation = useCallback(() => {
    if (forceShow && onHide) {
      onHide(forceShow)
    }

    if (listenerCleanupRef.current) {
      listenerCleanupRef.current()
      listenerCleanupRef.current = null
    }
    setShowConfirmation(false)
    if (intendedPathRef.current) {
      navigate(intendedPathRef.current)
      intendedPathRef.current = null
    }
  }, [navigate, forceShow, onHide])

  const handleSaveAndLeave = useCallback(() => {
    if (forceShow && onSave) {
      onSave()
      handleConfirmNavigation()
    }
  }, [forceShow, onSave])

  const handleCancelNavigation = useCallback(() => {
    if (forceShow && onHide) {
      onHide()
    } else {
      setShowConfirmation(false)
    }
  }, [forceShow, onHide])

  if (!showConfirmation && !forceShow) return null

  return (
    <ConfirmationDialog
      title={t('navigate_away_alert_title')}
      content={t('navigate_away_alert_info')}
      onClose={handleCancelNavigation}
      onCancel={handleConfirmNavigation}
      cancelText={t('leave_without_saving')}
      cancelButtonWidth='14.5rem'
      onAction={handleSaveAndLeave}
      actionText={t('save_and_leave')}
      actionButtonWidth='14.5rem'
      width='35rem'
      buttonFontSize='1rem'
      open={true}
      chipClose
    />
  )
}
