import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import { ComponentTypes } from '../../../Utils/layout'
import { WallLayout, RoomPartSections } from '../../../Utils/room'

const SpaceWall = (props: any) => {
  const { sessionStore, spaceStore }: any = useStore()
  const { isAdmin } = sessionStore
  const { wall, space, hasUnsavedChanges } = spaceStore
  const { t } = useTranslation()

  const { wallNumber } = props
  
  const coatingAndMaterial: any = useRef({})
  const windowAndDoor: any = useRef({})
  const heating: any = useRef({})
  const waterFixture: any = useRef({})
  const drainage: any = useRef({})
  const waterProofing: any = useRef({})
  const ventilation: any = useRef({})
  const socketAndLighting: any = useRef({})
  const hearth: any = useRef({})
  const electricalDevice: any = useRef({})
  const fixture: any = useRef({})
  const cooling: any = useRef({})
  
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  const [openSections, setOpenSections] = useState([
    ...WallLayout.map((section: any) => section.title)
  ])

  const resetRefs = () => {
    coatingAndMaterial.current = {}
    windowAndDoor.current = {}
    heating.current = {}
    waterFixture.current = {}
    drainage.current = {}
    waterProofing.current = {}
    ventilation.current = {}
    socketAndLighting.current = {}
    hearth.current = {}
    electricalDevice.current = {}
    fixture.current = {}
    cooling.current = {}
  }

  useEffect(() => {
    resetRefs()
    setLastUpdatedValue(DateTime.now().toISO())
  }, [wallNumber])

  const calculateTotalLightSwitchCount = () => {
    let sum = 0
    if (socketAndLighting.current?.lightSwitchDimmerCount) {
      const dimmerCount = parseInt(socketAndLighting.current?.lightSwitchDimmerCount)
      if (dimmerCount && !isNaN(dimmerCount)) {
        sum += dimmerCount
      }
    }
    if (socketAndLighting.current?.lightSwitchOneSwitchCount) {
      const oneSwitchCount = parseInt(socketAndLighting.current?.lightSwitchOneSwitchCount)
      if (oneSwitchCount && !isNaN(oneSwitchCount)) {
        sum += oneSwitchCount
      }
    }
    if (socketAndLighting.current?.lightSwitchTwoSwitchCount) {
      const twoSwitchCount = parseInt(socketAndLighting.current?.lightSwitchTwoSwitchCount)
      if (twoSwitchCount && !isNaN(twoSwitchCount)) {
        sum += twoSwitchCount
      }
    }
    if (socketAndLighting.current?.lightSwitchThreeSwitchCount) {
      const threeSwitchCount = parseInt(socketAndLighting.current?.lightSwitchThreeSwitchCount)
      if (threeSwitchCount && !isNaN(threeSwitchCount)) {
        sum += threeSwitchCount
      }
    }
    if (socketAndLighting.current?.lightSwitchOtherCount) {
      const otherCount = parseInt(socketAndLighting.current?.lightSwitchOtherCount)
      if (otherCount && !isNaN(otherCount)) {
        sum += otherCount
      }
    }
    return sum
  }

  useEffect(() => {
    if (wall) {
      for (const [key, value] of Object.entries(wall?.coatingAndMaterial || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'coating') {
            coatingAndMaterial.current[key] = wall?.coatingAndMaterial?.coating?.length ? 
              wall?.coatingAndMaterial?.coating?.split(',') :
              []
          } else {
            coatingAndMaterial.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(wall?.windowAndDoor || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          windowAndDoor.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.heating || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          heating.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.waterFixture || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          waterFixture.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.drainage || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          drainage.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.waterProofing || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          waterProofing.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.ventilation || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          ventilation.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(wall?.socketAndLighting || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'lightSwitchTypes') {
            socketAndLighting.current[key] = wall?.socketAndLighting?.lightSwitchTypes?.length ? 
              wall?.socketAndLighting?.lightSwitchTypes?.split(',') :
              []
          } else {
            socketAndLighting.current[key] = value
          }
        }
      }
      socketAndLighting.current['lightSwitchCount'] = calculateTotalLightSwitchCount()
      for (const [key, value] of Object.entries(wall?.hearth || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'hearthTypes') {
            hearth.current[key] = wall?.hearth?.hearthTypes?.length ? 
              wall?.hearth?.hearthTypes?.split(',') :
              []
          } else {
            hearth.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(wall?.electricalDevice || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'electricalDevices') {
            electricalDevice.current[key] = wall?.electricalDevice?.electricalDevices?.length ? 
              wall?.electricalDevice?.electricalDevices?.split(',') :
              []
          } else {
            electricalDevice.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(wall?.fixture || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'fixtures') {
            fixture.current[key] = wall?.fixture?.fixtures?.length ? 
              wall?.fixture?.fixtures?.split(',') :
              []
          } else {
            fixture.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(wall?.cooling || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          cooling.current[key] = value
        }
      }
      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [wall])

  const onSave = () => {
    const updatedSpace = {
      coatingAndMaterial: {
        ...coatingAndMaterial.current,
        coating: coatingAndMaterial.current?.coating?.join(',') || ''
      },
      windowAndDoor: windowAndDoor.current,
      heating: heating.current,
      waterFixture: waterFixture.current,
      drainage: drainage.current,
      waterProofing: waterProofing.current,
      ventilation: ventilation.current,
      socketAndLighting: {
        ...socketAndLighting.current,
        lightSwitchTypes: socketAndLighting.current?.lightSwitchTypes?.join(',') || ''
      },
      hearth: {
        ...hearth.current,
        hearthTypes: hearth.current?.hearthTypes?.join(',') || ''
      },
      electricalDevice: {
        ...electricalDevice.current,
        electricalDevices: electricalDevice.current?.electricalDevices?.join(',') || ''
      },
      fixture: {
        ...fixture.current,
        fixtures: fixture.current?.fixtures?.join(',') || ''
      }
      // cooling: cooling.current
    }
    spaceStore.updateSpaceWall(props.pUuid, props.bUuid, wallNumber, updatedSpace)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    [wallNumber]
  )

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const onChange = (category: string, key: string, value: any) => {
    if (category === RoomPartSections.CoatingsAndMaterials) {
      coatingAndMaterial.current = { ...(coatingAndMaterial?.current || {}), [key]: value }
    } else if (category === RoomPartSections.WindowsAndDoors) {
      windowAndDoor.current = { ...(windowAndDoor?.current || {}), [key]: value }
    } else if (category === RoomPartSections.Heating) {
      heating.current = { ...(heating?.current || {}), [key]: value }
    } else if (category === RoomPartSections.WaterFixtures) {
      waterFixture.current = { ...(waterFixture?.current || {}), [key]: value }
    } else if (category === RoomPartSections.Drainage) {
      drainage.current = { ...(drainage?.current || {}), [key]: value }
    } else if (category === RoomPartSections.WaterProofing) {
      waterProofing.current = { ...(waterProofing?.current || {}), [key]: value }
    } else if (category === RoomPartSections.Ventilation) {
      ventilation.current = { ...(ventilation?.current || {}), [key]: value }
    } else if (category === RoomPartSections.SocketsAndLighting) {
      socketAndLighting.current = { ...(socketAndLighting?.current || {}), [key]: value }
      if (
        key === 'lightSwitchDimmerCount' ||
        key === 'lightSwitchOneSwitchCount' ||
        key === 'lightSwitchTwoSwitchCount' ||
        key === 'lightSwitchThreeSwitchCount' ||
        key === 'lightSwitchOtherCount'
      ) {
        socketAndLighting.current['lightSwitchCount'] = calculateTotalLightSwitchCount()
      }
    } else if (category === RoomPartSections.Hearths) {
      hearth.current = { ...(hearth?.current || {}), [key]: value }
    } else if (category === RoomPartSections.ElectricalDevices) {
      electricalDevice.current = { ...(electricalDevice?.current || {}), [key]: value }
    } else if (category === RoomPartSections.Fixtures) {
      fixture.current = { ...(fixture?.current || {}), [key]: value }
    } else if (category === RoomPartSections.Cooling) {
      cooling.current = { ...(cooling?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${key}_${value}`)
    if (!hasUnsavedChanges) {
      spaceStore.setHasUnsavedChanges(true)
    }
  }

  const getValues = useCallback((category: string) => {
    if (category === RoomPartSections.CoatingsAndMaterials) {
      return coatingAndMaterial?.current || {}
    }  else if (category === RoomPartSections.WindowsAndDoors) {
      return windowAndDoor?.current || {}
    } else if (category === RoomPartSections.Heating) {
      return heating?.current || {}
    } else if (category === RoomPartSections.WaterFixtures) {
      return waterFixture?.current || {}
    } else if (category === RoomPartSections.Drainage) {
      return drainage?.current || {}
    } else if (category === RoomPartSections.WaterProofing) {
      return waterProofing?.current || {}
    } else if (category === RoomPartSections.Ventilation) {
      return ventilation?.current || {}
    } else if (category === RoomPartSections.SocketsAndLighting) {
      return socketAndLighting?.current || {}
    } else if (category === RoomPartSections.Hearths) {
      return hearth?.current || {}
    } else if (category === RoomPartSections.ElectricalDevices) {
      return electricalDevice?.current || {}
    } else if (category === RoomPartSections.Fixtures) {
      return fixture?.current || {}
    } else if (category === RoomPartSections.Cooling) {
      return cooling?.current || {}
    }
  }, [coatingAndMaterial, windowAndDoor, heating, waterFixture, drainage, waterProofing, ventilation, socketAndLighting, hearth, electricalDevice, fixture, cooling])

  const renderCustomForm = (sections: any, title: string, index: number) => {
    if (sections?.length) {
      // Extract values from the current category
      const values = getValues(title)
      // Filter out items that should not be visible
      const items = sections.map((section: any) => {
        if (!section?.visibleIfValues && !section?.items && !section?.items?.find((item: any) => item?.visibleIfValues)) {
          // If no visibleIfValues are defined, show the item
          return section
        }
        if (!section?.visibleIfValues) {
          if (section?.items) {
            return {
              ...section,
              // Filter out items that should not be visible
              items: section?.items?.filter((item: any) => {
                if (space?.hiddenFields?.length && space.hiddenFields?.includes(item?.key)) {
                  return false
                }

                if (!item?.visibleIfValues) {
                  return true
                }
                return item?.visibleIfValues?.every((visibleIfValue: any) => {
                  // const values = getValues(visibleIfValue.key?.split('_')[0])
                  if (Array.isArray(values?.[visibleIfValue.key])) {
                    return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
                  }
                  return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
                })
              })
            }
          }
        }
        // Check if all visibleIfValues are met
        const conditionsMet = section?.visibleIfValues?.every((visibleIfValue: any) => {
          if (Array.isArray(values?.[visibleIfValue.key])) {
            return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
          }
          // const values = getValues(visibleIfValue.key?.split('_')[0])
          return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
        })
        if (conditionsMet) {
          return section
        }
        return null
      }).filter((item: any) => item)

      if (items?.length === 1 && items[0]?.type === ComponentTypes.Subtitle) {
        return null
      }
      if (!items?.length) {
        return null
      }
      return (
        <CustomForm
          key={`form-${title}-${index}`}
          section={items}
          values={values}
          onChange={(key: string, value: string) => onChange(title, key, value)}
          lastUpdatedValue={lastUpdatedValue}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title: string): any => {
    return sections.map((section: any, index: number) => renderCustomForm(section, title, index))
  }

  const SectionsLayout = useMemo(() => {
    return WallLayout.map((layout: any) => {
      if (!layout?.sections?.length) {
        return null
      }
      if (space?.hiddenItems?.[`wall${wallNumber}`]?.includes(layout?.title)) {
        return null
      }
      return (
        <CollapseSection
          key={`section-${layout.title}`}
          title={t(layout?.label ?? layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections, layout.title)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue, space])

  // Do not show anything if params are missing
  const pUuid = props?.pUuid || null
  const bUuid = props?.bUuid || null
  if (!pUuid || pUuid?.length !== 36 || !bUuid || bUuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{SectionsLayout}</Box>
    </Box>
  )
}

export default observer(SpaceWall)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  },
  loading: {
    margin: 'auto'
  }
} as const
