import {
  Subtitle,
  Input,
  Select,
  MultiSelect,
  Row,
  OtherOption,
  OtherPlaceholder,
  UnknownOption,
  UnknownPlaceholder,
  YearOptions,
  Option,
  IfOtherSelected,
  IfUnknownSelected,
  BooleanOptions,
  IfYesSelected,
  IfCustomSelected
} from './layout'

export enum RoomSections {
  // Pinnoitteet ja materiaalit
  CoatingsAndMaterials = 'coatings_and_materials',
  // Ikkunat ja ovet
  WindowsAndDoors = 'windows_and_doors',
  // Lämmitys
  Heating = 'heating',
  // Viemäröinti
  Drainage = 'drainage',
  // Vesikalusteet
  WaterFixtures = 'water_fixtures',
  // Vedeneristys
  WaterProofing = 'water_proofing',
  // Ilmanvaihto
  Ventilation = 'ventilation',
  // Pistorasiat ja valaistus
  SocketsAndLighting = 'sockets_and_lighting',
  // Tulisijat
  Hearths = 'hearths',
  // Sähkölaitteet
  ElectricalDevices = 'electrical_devices',
  // Kiinteät kalusteet
  Fixtures = 'fixtures',
  // Viilennys
  Cooling = 'cooling'
}

const {
  CoatingsAndMaterials,
  WindowsAndDoors,
  Heating,
  Drainage,
  WaterFixtures,
  WaterProofing,
  Ventilation,
  SocketsAndLighting,
  Hearths,
  ElectricalDevices,
  Fixtures,
} = RoomSections

export const RoomSectionOptions = [
  Option(CoatingsAndMaterials),
  Option(WindowsAndDoors),
  Option(Heating),
  Option(Drainage),
  Option(WaterFixtures),
  Option(WaterProofing),
  Option(Ventilation),
  Option(SocketsAndLighting),
  Option(Hearths),
  Option(ElectricalDevices),
  Option(Fixtures),
]

export const RoomSectionItemsMappings = {
  'floor': [
    CoatingsAndMaterials,
    Heating,
    WaterFixtures,
    Drainage,
    WaterProofing,
    Ventilation,
    SocketsAndLighting,
    Hearths,
    ElectricalDevices,
    Fixtures,
  ],
  'ceiling': [
    CoatingsAndMaterials,
    WindowsAndDoors,
    Ventilation,
    SocketsAndLighting,
    Hearths,
    ElectricalDevices,
    Fixtures,
  ],
  'wall': [
    CoatingsAndMaterials,
    WindowsAndDoors,
    Heating,
    WaterFixtures,
    Drainage,
    WaterProofing,
    Ventilation,
    SocketsAndLighting,
    Hearths,
    ElectricalDevices,
    Fixtures,
  ]
}

// Kattomateriaalit
export enum CeilingMaterials {
  // Maali
  Paint = 'paint',
  // Paneeli
  Panel = 'panel'
}

// Lattiamateriaalit
export enum FloorMaterials {
  // Laatta
  Tile = 'tile',
  // Laminaatti
  Laminate = 'laminate',
  // Parketti
  Parquet = 'parquet',
  // Muovimatto
  Vinyl = 'vinyl',
  // Kivi
  Stone = 'stone'
}

// Seinämateriaalit
export enum WallMaterials {
  // Maali
  Paint = 'paint',
  // Tapetti
  Wallpaper = 'wallpaper',
  // Laatta
  Tile = 'tile',
  // Paneeli
  Panel = 'panel'
}

// Palovaroitintyypit
export enum SmokeDetectorTypes {
  // Savu
  Smoke = 'smoke',
  // Häkä
  CarbonMonoxide = 'carbon_monoxide'
}

// Ikkunatyypit
export enum WindowTypes {
  // 1-lasinen
  One_Glass = 'one_glass',
  // 2-lasinen
  Two_Glass = 'two_glass',
  // 3-lasinen
  Three_Glass = 'three_glass'
}

// Ikkunan ilmansuunnat
export enum WindowDirections {
  North = 'north',
  // NorthEast = 'north_east',
  // NorthWest = 'north_west',
  South = 'south',
  // SouthEast = 'south_east',
  // SouthWest = 'south_west',
  East = 'east',
  West = 'west'
}

// Valaisinpisteet
export enum LightSocketTypes {
  // Pistoke
  Socket = 'socket',
  // Sokeripala
  SugarCube = 'sugar_cube'
}

// Pistokkeet
export enum PowerSocketTypes {
  // TV-antenni
  TvAntenna = 'tv_antenna',
  // Radio antenni
  RadioAntenna = 'radio_antenna',
  // Puhelin
  Telephone = 'telephone',
  // Tietoliikenne
  Data = 'data',
  // Sähkö
  Power = 'power'
}

// Valokatkaisijat
export enum LightSwitchTypes {
  // Himmentävä
  Dimmer = 'dimmer',
  // 1-katkaisijaa
  OneSwitch = 'one_switch',
  // 2-katkaisijaa
  TwoSwitch = 'two_switch',
  // 3-katkaisijaa
  ThreeSwitch = 'three_switch'
}

// Lattiakaivot
export enum FloorDrainTypes {
  // Valurauta
  CastIron = 'cast_iron',
  // Muovi
  Plastic = 'plastic'
}

// Lavuaarit
export enum SinkTypes {
  // Posliini
  Porcelain = 'porcelain',
  // Metalli
  Metal = 'metal'
}

// Energialuokat
export enum EnergyClasses {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g'
}

// Liesityypit
export enum StoveTypes {
  // Induktio
  Induction = 'induction',
  // Keraaminen
  Ceramic = 'ceramic',
  // Valurauta
  CastIron = 'cast_iron'
}

// Uunilla on kiertoilma
export const OvenHasCirculatingAirOptions = [
  { value: true, label: 'Kyllä' },
  { value: false, label: 'Ei' }
]

// Kiuastyypit
export enum SaunaStoveTypes {
  // Sähkö
  Electric = 'electric',
  // Puu
  Wood = 'wood'
}

// Tulisijat
export enum FireplaceTypes {
  // Avonainen
  Open = 'open',
  // Umpinainen
  Closed = 'closed',
  // Varaava
  Accumulating = 'accumulating'
}

// Jääkaapit
export enum RefrigeratorTypes {
  // Jääkaappi
  Refrigerator = 'refrigerator',
  // Jääkaappi-pakastin
  RefrigeratorWithFreezer = 'refrigerator_with_freezer'
}

// Pakastimet
export enum FreezerTypes {
  // Arkkupakastin
  ChestFreezer = 'chest_freezer',
  // Kaappi-pakastin
  Freezer = 'freezer'
}

// Ovityypit
export enum DoorTypes {
  // Palo-ovi
  FireDoor = 'fire_door',
  // Ulko-ovi
  ExteriorDoor = 'exterior_door',
  // Väliovi
  InteriorDoor = 'interior_door'
}

// WC-istuimet
export enum ToiletTypes {
  // Lattia-asennus
  FloorMounted = 'floor_mounted',
  // Seinä-asennus
  WallMounted = 'wall_mounted'
}

// WC-istuimen materiaalit
export enum ToiletMaterials {
  // Metalli
  Metal = 'metal',
  // Posliini
  Porcelain = 'porcelain'
}

// Suihkukaapit
export enum ShowerCabinTypes {
  // Ovella
  WithDoor = 'with_door',
  // Verholla
  WithCurtain = 'with_curtain'
}

// Suihkukaapissa allas
export const ShowerCabinHasSinkOptions = [
  { value: true, label: 'Kyllä' },
  { value: false, label: 'Ei' }
]

// Kylpyammeet
export enum BathtubTypes {
  // Poreilla
  WithBubbles = 'with_bubbles',
  // Ilman poreita
  WithoutBubbles = 'without_bubbles'
}

// Palovaroittimien määrät
export const SmokeDetectorCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Seinien määrät
export const WallCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Ikkunoiden määrät
export const WindowCountOptions = {
  ...Array.from({ length: 10 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Ovien määrät
export const DoorCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

export enum RoomTypes {
  // Eteinen
  Hallway = 'hallway',
  // Olohuone
  LivingRoom = 'living_room',
  // Makuuhuone
  Bedroom = 'bedroom',
  // Keittiö
  Kitchen = 'kitchen',
  // WC
  Wc = 'wc',
  // Pesutilat
  Bathroom = 'bathroom',
  // Sauna
  Sauna = 'sauna',
  // Kodinhoitohuone
  UtilityRoom = 'utility_room',
  // Vaatehuone
  WalkInCloset = 'walk_in_closet',
  // Parveke
  Balcony = 'balcony',
  // Tuulikaappi
  Vestibule = 'vestibule',
  // Muu tila
  Other = 'other'
}

export enum RoomPartSections {
  // Pinnoitteet ja materiaalit
  CoatingsAndMaterials = 'coatings_and_materials',
  // Ikkunat ja ovet
  WindowsAndDoors = 'windows_and_doors',
  // Lämmitys
  Heating = 'heating',
  // Vesikalusteet
  WaterFixtures = 'water_fixtures',
  // Viemäröinti
  Drainage = 'drainage',
  // Vedeneristys
  WaterProofing = 'water_proofing',
  // Ilmanvaihto
  Ventilation = 'ventilation',
  // Pistorasiat ja valaistus
  SocketsAndLighting = 'sockets_and_lighting',
  // Tulisijat
  Hearths = 'hearths',
  // Sähkölaitteet
  ElectricalDevices = 'electrical_devices',
  // Kiinteät kalusteet
  Fixtures = 'fixtures',
  // Viilennys
  Cooling = 'cooling'
}

const CoatingsAndMaterialsSection = (roomPart: string) => [
  [
    Subtitle('basic_information'),
    {
      key: 'frameMaterial',
      label: 'frame_material',
      ...Select,
      options: [
        ...(roomPart === 'floor' ? [
          Option('concrete_block'),
          Option('steel'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption,
          UnknownOption
        ] : roomPart === 'ceiling' ? [
          Option('concrete_block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          Option('lightweight_concrete_other', 'lightweight_concrete_alt'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption
        ] : [
          Option('concrete_block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          OtherOption
        ])
      ]
    },
    {
      key: 'frameMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('frameMaterial')],
      ...Input
    },
    {
      key: 'frameMaterialNotes',
      ...UnknownPlaceholder,
      visibleIfValues: [IfUnknownSelected('frameMaterial')],
      ...Input
    },
    {
      key: 'coating',
      label: 'coatings',
      ...MultiSelect,
      options: [
        ...(roomPart === 'floor' ? [
          Option('paint'),
          Option('board'),
          Option('plastic_mat'),
          Option('laminate'),
          Option('parquet'),
          Option('cork'),
          Option('vinyl'),
          Option('epoxy'),
          Option('tile'),
          Option('microcement'),
          Option('uncoated'),
          Option('other', 'other_type')
        ] : roomPart === 'ceiling' ? [
          Option('panel'),
          Option('sparse_board'),
          Option('paint'),
          Option('spray_plaster'),
          Option('batten'),
          Option('uncoated'),
          Option('other', 'other_type')
        ] : [
          Option('paint'),
          Option('wallpaper'),
          Option('fiberglass_wallpaper'),
          Option('panel'),
          Option('tile'),
          Option('spray_plaster'),
          Option('uncoated'),
          Option('other', 'other_type')
        ])
      ] 
    },
    {
      key: 'otherCoating',
      label: 'other_coating',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('coating')],
      ...Input
    },
    (roomPart === 'ceiling' ? 
      {
        key: 'hasSuspendedCeiling',
        label: 'has_suspended_ceiling',
        ...Select,
        options: [
          ...BooleanOptions,
          Option('in_part')
        ]
      } : undefined)
  ]
]

const WindowsAndDoorsSection = (roomPart: string) => [
  [
    Subtitle('counts'),
    {
      ...Row,
      items: [
        {
          key: 'windowCount',
          label: 'windows',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'interiorDoorCount',
          label: 'interior_doors',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'exteriorDoorCount',
          label: 'exterior_doors',
          ...Input,
          unit: 'kpl'
        }
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      items: [
        {
          key: 'windowType',
          label: 'window_type',
          ...Select,
          options: [
            ...(roomPart === 'ceiling' ? [
              Option('openable'),
              Option('ceiling_dome'),
              OtherOption
            ] : [
              Option('msu'),
              Option('ms'),
              Option('msk'),
              Option('mse'),
              Option('msea'),
              Option('mek'),
              Option('meka'),
              Option('se'),
              Option('ms2e'),
              Option('ms3e'),
              Option('dk')
            ])
          ]
        },
        {
          key: 'windowInstallationYear',
          label: 'window_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    roomPart === 'ceiling' ? {
      key: 'windowTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('windowType')],
      ...Input
    } : undefined,
    {
      ...Row,
      items: [
        {
          key: 'interiorDoorType',
          label: 'interior_door_type',
          ...Select,
          options: [
            Option('single_leaf', 'single_leaf_door'),
            Option('double_leaf', 'double_leaf_door'),
            Option('revolving', 'revolving_door'),
            Option('overhead', 'overhead_door'),
            Option('sliding', 'sliding_door'),
            OtherOption
          ]
        },
        {
          key: 'interiorDoorInstallationYear',
          label: 'interior_door_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'interiorDoorTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('interiorDoorType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'exteriorDoorType',
          label: 'exterior_door_type',
          ...Select,
          options: [
            Option('single_leaf', 'single_leaf_door'),
            Option('double_leaf', 'double_leaf_door'),
            Option('revolving', 'revolving_door'),
            Option('overhead', 'overhead_door'),
            Option('sliding', 'sliding_door'),
            OtherOption
          ]
        },
        {
          key: 'exteriorDoorInstallationYear',
          label: 'exterior_door_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'exteriorDoorTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('exteriorDoorType')],
      ...Input
    },
  ]
]

const HeatingSection = [
  [
    Subtitle('basic_information'),
    {
      ...Row,
      items: [
        {
          key: 'manifoldCount',
          label: 'manifolds',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'manifoldInstallationYear',
          label: 'manifold_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'thermostatCount',
          label: 'thermostats',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'thermostatInstallationYear',
          label: 'thermostat_installation_year',
          ...Select,
          options: YearOptions(),
          disabled: true
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'radiatorCount',
          label: 'radiators',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'radiatorInstallationYear',
          label: 'radiator_installation_year',
          ...Select,
          options: YearOptions(),
          disabled: true
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'airHeatPumpCount',
          label: 'air_heat_pumps',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'airHeatPumpInstallationYear',
          label: 'air_heat_pump_installation_year',
          ...Select,
          options: YearOptions(),
          disabled: true
        }
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      items: [
        {
          key: 'manifoldManufacturer',
          label: 'manifold_manufacturer',
          ...Input
        },
        {
          key: 'manifoldModel',
          label: 'manifold_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'thermostatManufacturer',
          label: 'thermostat_manufacturer',
          ...Input
        },
        {
          key: 'thermostatModel',
          label: 'thermostat_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'radiatorManufacturer',
          label: 'radiator_manufacturer',
          ...Input
        },
        {
          key: 'radiatorModel',
          label: 'radiator_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'airHeatPumpManufacturer',
          label: 'air_heat_pump_manufacturer',
          ...Input
        },
        {
          key: 'airHeatPumpModel',
          label: 'air_heat_pump_model',
          ...Input
        }
      ]
    }
  ]
]

const WaterFixturesSection = [
  [
    Subtitle('counts'),
    {
      ...Row,
      items: [
        {
          key: 'basinMixerCount',
          label: 'basin_mixer',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'showerMixerCount',
          label: 'shower_mixer',
          ...Input,
          unit: 'kpl'
        },
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'waterDryingRadiatorCount',
          label: 'water_drying_radiator',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'toiletSeatCount',
          label: 'toilet_seat',
          ...Input,
          unit: 'kpl'
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'hasBidetShower',
          label: 'has_wall_mounted_bidet_shower',
          ...Select,
          options: BooleanOptions
        },
        {
          key: 'bidetShowerCount',
          label: 'bidet_shower',
          visibleIfValues: [IfYesSelected('hasBidetShower')],
          ...Input,
          unit: 'kpl'
        }
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      items: [
        {
          key: 'basinMixerManufacturer',
          label: 'basin_mixer_manufacturer',
          ...Input
        },
        {
          key: 'basinMixerModel',
          label: 'basin_mixer_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'showerMixerManufacturer',
          label: 'shower_mixer_manufacturer',
          ...Input
        },
        {
          key: 'showerMixerModel',
          label: 'shower_mixer_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'waterDryingRadiatorManufacturer',
          label: 'water_drying_radiator_manufacturer',
          ...Input
        },
        {
          key: 'waterDryingRadiatorModel',
          label: 'water_drying_radiator_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'toiletSeatManufacturer',
          label: 'toilet_seat_manufacturer',
          ...Input
        },
        {
          key: 'toiletSeatModel',
          label: 'toilet_seat_model',
          ...Input
        }
      ]
    },
    {
      ...Row,
      items: [
        {
          key: 'bidetShowerManufacturer',
          label: 'bidet_shower_manufacturer',
          ...Input
        },
        {
          key: 'bidetShowerModel',
          label: 'bidet_shower_model',
          ...Input
        }
      ]
    }
  ]
]

const DrainageSection = [
  [
    Subtitle('sink'),
    {
      key: 'sinkMaterial',
      label: 'sink_material',
      ...Select,
      options: [
        Option('porcelain'),
        Option('metal'),
        Option('wood'),
        Option('composite'),
        Option('stone'),
        OtherOption
      ]
    },
    {
      key: 'sinkMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('sinkMaterial')],
      ...Input
    },
    {
      key: 'drainTrapMaterial',
      label: 'drain_trap_material',
      ...Select,
      options: [
        Option('plastic'),
        Option('metal'),
        OtherOption
      ]
    },
    {
      key: 'drainTrapMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('drainTrapMaterial')],
      ...Input
    },
    {
      key: 'drainTrapConnectionType',
      label: 'drain_trap_connection_type',
      ...Select,
      options: [
        Option('floor', 'on_floor'),
        Option('wall', 'on_wall'),
        OtherOption
      ]
    },
    {
      key: 'drainTrapConnectionTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('drainTrapConnectionType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'drainTrapCount',
          label: 'drain_trap_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'drainTrapInstallationYear',
          label: 'drain_trap_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ],
  [
    Subtitle('wells'),
    {
      key: 'waterSealedDrainType',
      label: 'water_sealed_drain_type',
      ...Select,
      options: [
        Option('floor_drain'),
        Option('linear_floor_drain'),
        Option('wall_drain'),
        OtherOption
      ]
    },
    {
      key: 'waterSealedDrainTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('waterSealedDrainType')],
      ...Input
    },
    {
      key: 'waterSealedDrainMaterial',
      label: 'water_sealed_drain_material',
      ...Select,
      options: [
        Option('plastic'),
        Option('copper'),
        Option('cast_iron'),
        OtherOption
      ]
    },
    {
      key: 'waterSealedDrainMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('waterSealedDrainMaterial')],
      ...Input
    },
    {
      key: 'waterSealedDrainManufacturer',
      label: 'water_sealed_drain_manufacturer',
      ...Select,
      options: [
        Option('merika'),
        Option('vieser'),
        Option('unidrain'),
        OtherOption
      ]
    },
    {
      key: 'waterSealedDrainManufacturerNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('waterSealedDrainManufacturer')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'waterSealedDrainCount',
          label: 'water_sealed_drain_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'waterSealedDrainInstallationYear',
          label: 'water_sealed_drain_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'waterSealedDrainExtensionRingMaterial',
      label: 'water_sealed_drain_extension_ring_material',
      ...Select,
      options: [
        Option('plastic'),
        Option('copper'),
        OtherOption
      ]
    },
    {
      key: 'waterSealedDrainExtensionRingMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('waterSealedDrainExtensionRingMaterial')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'waterSealedDrainExtensionRingCount',
          label: 'water_sealed_drain_extension_ring_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'waterSealedDrainExtensionRingInstallationYear',
          label: 'water_sealed_drain_extension_ring_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'dryDrainType',
      label: 'dry_drain_type',
      ...Select,
      options: [
        Option('floor_drain'),
        Option('linear_floor_drain'),
        Option('wall_drain'),
        OtherOption
      ]
    },
    {
      key: 'dryDrainTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('dryDrainType')],
      ...Input
    },
    {
      key: 'dryDrainMaterial',
      label: 'dry_drain_material',
      ...Select,
      options: [
        Option('plastic'),
        Option('copper'),
        Option('cast_iron'),
        OtherOption
      ]
    },
    {
      key: 'dryDrainMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('dryDrainMaterial')],
      ...Input
    },
    {
      key: 'dryDrainManufacturer',
      label: 'dry_drain_manufacturer',
      ...Select,
      options: [
        Option('merika'),
        Option('vieser'),
        Option('unidrain'),
        OtherOption
      ]
    },
    {
      key: 'dryDrainManufacturerNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('dryDrainManufacturer')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'dryDrainCount',
          label: 'dry_drain_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'dryDrainInstallationYear',
          label: 'dry_drain_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'dryDrainExtensionRingMaterial',
      label: 'dry_drain_extension_ring_material',
      ...Select,
      options: [
        Option('plastic'),
        Option('copper'),
        OtherOption
      ]
    },
    {
      key: 'dryDrainExtensionRingMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('dryDrainExtensionRingMaterial')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'dryDrainExtensionRingCount',
          label: 'dry_drain_extension_ring_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'dryDrainExtensionRingInstallationYear',
          label: 'dry_drain_extension_ring_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ],
  [
    Subtitle('others'),
    {
      key: 'washingMachineWaterConnection',
      label: 'washing_machine_water_connection',
      ...Select,
      options: [
        Option('wall'),
        Option('sink'),
        OtherOption
      ]
    },
    {
      key: 'washingMachineWaterConnectionNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('washingMachineWaterConnection')],
      ...Input
    },
    {
      key: 'washingMachineDrainConnection',
      label: 'washing_machine_drain_connection',
      ...Select,
      options: [
        Option('floor'),
        Option('wall'),
        Option('sink_drain_trap'),
        OtherOption
      ]
    },
    {
      key: 'washingMachineDrainConnectionNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('washingMachineDrainConnection')],
      ...Input
    },
    {
      key: 'showerCabinType',
      label: 'shower_cabin_type',
      ...Select,
      options: [
        Option('fixture'),
        Option('retrofit'),
        Option('partially_open'),
        OtherOption
      ]
    },
    {
      key: 'showerCabinTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('showerCabinType')],
      ...Input
    },
    {
      key: 'showerCabinDrainageType',
      label: 'shower_cabin_drainage_type',
      ...Select,
      options: [
        Option('drain'),
        Option('floor'),
        OtherOption
      ]
    },
    {
      key: 'showerCabinDrainageTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('showerCabinDrainageType')],
      ...Input
    }
  ]
]

const WaterProofingSection = [
  [
    Subtitle('basic_information'),
    {
      key: 'waterProofingMaterial',
      label: 'water_proofing_material',
      ...Select,
      options: [
        Option('modern_brushable_compound'),
        Option('moisture_barrier_coating'),
        Option('plastic_mat'),
        Option('plastic_wallpaper'),
        Option('bitumen_roll'),
        Option('liquid_bitumen'),
        Option('no', 'no_water_proofing'),
        OtherOption
      ]
    },
    {
      key: 'waterProofingMaterialNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('waterProofingMaterial')],
      ...Input
    },
    {
      key: 'waterProofingInstallationYear',
      label: 'water_proofing_installation_year',
      ...Select,
      options: YearOptions()
    }
  ]
]

const VentilationSection = [
  [
    Subtitle('counts'),
    {
      ...Row,
      items: [
        {
          key: 'exhaustVentilationUnitCount',
          label: 'exhaust_ventilation_units',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'supplyVentilationUnitCount',
          label: 'supply_ventilation_units',
          ...Input,
          unit: 'kpl'
        }
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      key: 'exhaustVentilationUnitType',
      label: 'exhaust_ventilation_unit_type',
      ...Select,
      options: [
        Option('poppet', 'poppet_valve'),
        Option('extract', 'extract_air_valve'),
        OtherOption
      ]
    },
    {
      key: 'exhaustVentilationUnitTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('exhaustVentilationUnitType')],
      ...Input
    },
    {
      key: 'exhaustVentilationUnitSize',
      label: 'exhaust_ventilation_unit_size',
      ...Select,
      options: [
        Option('100'),
        Option('125'),
        Option('160'),
        Option('200'),
        Option('250'),
        Option('300'),
        OtherOption
      ]
    },
    {
      key: 'exhaustVentilationUnitSizeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('exhaustVentilationUnitSize')],
      ...Input
    },
    {
      key: 'supplyVentilationUnitType',
      label: 'supply_ventilation_unit_type',
      ...Select,
      options: [
        Option('supply', 'supply_air_valve'),
        Option('supply_diffuser', 'supply_air_diffuser'),
        Option('poppet', 'poppet_valve'),
        OtherOption
      ]
    },
    {
      key: 'supplyVentilationUnitTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('supplyVentilationUnitType')],
      ...Input
    },
    {
      key: 'supplyVentilationUnitSize',
      label: 'supply_ventilation_unit_size',
      ...Select,
      options: [
        Option('100'),
        Option('125'),
        Option('160'),
        Option('200'),
        Option('250'),
        Option('300'),
        OtherOption
      ]
    },
    {
      key: 'supplyVentilationUnitSizeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('supplyVentilationUnitSize')],
      ...Input
    }
  ]
]

const SocketAndLightingSection = [
  [
    Subtitle('counts'),
    {
      ...Row,
      items: [
        {
          key: 'powerSocketCount',
          label: 'power_sockets',
          ...Input,
          unit: 'kpl',
          disabled: true
        },
        {
          key: 'dataSocketCount',
          label: 'data_sockets',
          ...Input,
          unit: 'kpl',
          disabled: true
        },
        {
          key: 'lightingSocketCount',
          label: 'lighting_sockets',
          ...Input,
          unit: 'kpl',
          disabled: true
        }
      ]
    }
  ],
  [
    Subtitle('sockets_additional_information'),
    {
      key: 'powerSocketType',
      label: 'power_socket_type',
      ...Select,
      options: [
        Option('tv_antenna'),
        Option('radio_antenna'),
        Option('telephone'),
        Option('data'),
        Option('power'),
        OtherOption
      ]
    },
    {
      key: 'powerSocketTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('powerSocketType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'powerSocketCount',
          label: 'power_socket_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'powerSocketInstallationYear',
          label: 'power_socket_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'dataSocketType',
      label: 'data_socket_type',
      ...Select,
      options: [
        Option('tv_antenna'),
        Option('radio_antenna'),
        Option('telephone'),
        Option('data'),
        Option('power'),
        OtherOption
      ]
    },
    {
      key: 'dataSocketTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('dataSocketType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'dataSocketCount',
          label: 'data_socket_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'dataSocketInstallationYear',
          label: 'data_socket_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'lightingSocketType',
      label: 'lighting_socket_type',
      ...Select,
      options: [
        Option('socket'),
        Option('sugar_cube'),
        OtherOption
      ]
    },
    {
      key: 'lightingSocketTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('lightingSocketType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'lightingSocketCount',
          label: 'lighting_socket_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightingSocketInstallationYear',
          label: 'lighting_socket_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ],
  [
    Subtitle('lighting_counts'),
    {
      ...Row,
      items: [
        {
          key: 'lightSwitchCount',
          label: 'light_switches',
          ...Input,
          unit: 'kpl',
          disabled: true
        },
        {
          key: 'lightingFixtureCount',
          label: 'lighting_fixtures',
          ...Input,
          unit: 'kpl',
          disabled: true
        }
      ]
    }
  ],
  [
    Subtitle('lighting_additional_information'),
    {
      key: 'lightSwitchTypes',
      label: 'light_switch_types',
      ...MultiSelect,
      options: [
        Option('dimmer'),
        Option('one_switch'),
        Option('two_switch'),
        Option('three_switch'),
        Option('other', 'other_type')
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('lightSwitchTypes', 'dimmer')],
      items: [
        {
          key: 'lightSwitchDimmerCount',
          label: 'dimmer_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchDimmerInstallationYear',
          label: 'dimmer_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('lightSwitchTypes', 'one_switch')],
      items: [
        {
          key: 'lightSwitchOneSwitchCount',
          label: 'one_switch_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchOneSwitchInstallationYear',
          label: 'one_switch_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('lightSwitchTypes', 'two_switch')],
      items: [
        {
          key: 'lightSwitchTwoSwitchCount',
          label: 'two_switch_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchTwoSwitchInstallationYear',
          label: 'two_switch_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('lightSwitchTypes', 'three_switch')],
      items: [
        {
          key: 'lightSwitchThreeSwitchCount',
          label: 'three_switch_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchThreeSwitchInstallationYear',
          label: 'three_switch_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'lightSwitchOtherType',
      label: 'light_switch_other_type',
      placeholder: 'enter_more_specific_description',
      visibleIfValues: [IfOtherSelected('lightSwitchTypes')],
      ...Input
    },
    {
      ...Row,
      visibleIfValues: [IfOtherSelected('lightSwitchTypes')],
      items: [
        {
          key: 'lightSwitchOtherCount',
          label: 'light_switch_other_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchOtherInstallationYear',
          label: 'light_switch_other_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    /*
    {
      key: 'lightSwitchType',
      label: 'light_switch_type',
      ...Select,
      options: [
        Option('dimmer'),
        Option('one_switch'),
        Option('two_switch'),
        Option('three_switch'),
        OtherOption
      ]
    },
    {
      key: 'lightSwitchTypeNotes',
      ...OtherPlaceholder,
      visibleIfValues: [IfOtherSelected('lightSwitchType')],
      ...Input
    },
    {
      ...Row,
      items: [
        {
          key: 'lightSwitchCount',
          label: 'light_switch_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightSwitchInstallationYear',
          label: 'light_switch_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    */
    {
      key: 'hasLightingFixtures',
      label: 'has_lighting_fixtures',
      ...Select,
      options: BooleanOptions
    },
    {
      ...Row,
      visibleIfValues: [IfYesSelected('hasLightingFixtures')],
      items: [
        {
          key: 'lightingFixtureCount',
          label: 'lighting_fixture_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'lightingFixtureInstallationYear',
          label: 'lighting_fixture_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ]
]

const HearthsSection = [
  [
    Subtitle('basic_information'),
    {
      key: 'hearthTypes',
      label: 'hearth_type',
      ...MultiSelect,
      options: [
        Option('sauna_stove'),
        Option('baking_oven'),
        Option('fireplace'),
        Option('open_fireplace'),
        Option('tiled_stove'),
        Option('barrel_stove'),
        Option('wood_stove')
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'sauna_stove')],
      items: [
        {
          key: 'saunaStoveCount',
          label: 'sauna_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'saunaStoveInstallationYear',
          label: 'sauna_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'baking_oven')],
      items: [
        {
          key: 'bakingOvenCount',
          label: 'baking_oven_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'bakingOvenInstallationYear',
          label: 'baking_oven_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'fireplace')],
      items: [
        {
          key: 'fireplaceCount',
          label: 'fireplace_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'fireplaceInstallationYear',
          label: 'fireplace_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'open_fireplace')],
      items: [
        {
          key: 'openFireplaceCount',
          label: 'open_fireplace_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'openFireplaceInstallationYear',
          label: 'open_fireplace_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'tiled_stove')],
      items: [
        {
          key: 'tiledStoveCount',
          label: 'tiled_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'tiledStoveInstallationYear',
          label: 'tiled_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'barrel_stove')],
      items: [
        {
          key: 'barrelStoveCount',
          label: 'barrel_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'barrelStoveInstallationYear',
          label: 'barrel_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('hearthTypes', 'wood_stove')],
      items: [
        {
          key: 'woodStoveCount',
          label: 'wood_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'woodStoveInstallationYear',
          label: 'wood_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ]
]

const ElectricalDevicesSection = (roomPart: string) => [
  [
    Subtitle('basic_information'),
    {
      key: 'electricalDevices',
      label: 'type',
      ...MultiSelect,
      options: [
        Option('electric_stove'),
        Option('electric_oven'),
        Option('range_hood'),
        Option('electric_sauna_stove'),
        Option('air_heat_pump'),
      ]
    }
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('electricalDevices', 'electric_stove')],
      items: [
        {
          key: 'electricStoveCount',
          label: 'electric_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'electricStoveInstallationYear',
          label: 'electric_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ],
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('electricalDevices', 'electric_oven')],
      items: [
        {
          key: 'electricOvenCount',
          label: 'electric_oven_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'electricOvenInstallationYear',
          label: 'electric_oven_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('electricalDevices', 'range_hood')],
      items: [
        {
          key: 'rangeHoodCount',
          label: 'range_hood_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'rangeHoodInstallationYear',
          label: 'range_hood_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('electricalDevices', 'electric_sauna_stove')],
      items: [
        {
          key: 'electricSaunaStoveCount',
          label: 'electric_sauna_stove_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'electricSaunaStoveInstallationYear',
          label: 'electric_sauna_stove_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('electricalDevices', 'air_heat_pump')],
      items: [
        {
          key: 'airHeatPumpCount',
          label: 'air_heat_pump_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'airHeatPumpInstallationYear',
          label: 'air_heat_pump_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    }
  ],
  roomPart === 'ceiling' ? [
    Subtitle('smoke_detector'),
    {
      key: 'hasSmokeDetector',
      label: 'smoke_detector',
      ...Select,
      options: BooleanOptions
    }
  ] : null
]

const FixturesSection = [
  [
    Subtitle('basic_information'),
    {
      key: 'fixtures',
      label: 'type',
      ...MultiSelect,
      options: [
        Option('sink_cabinet'),
        Option('cabinet'),
        Option('island'),
        Option('shelf'),
        Option('other', 'other_type')
      ]
    },
  ],
  [
    Subtitle('additional_information'),
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('fixtures', 'sink_cabinet')],
      items: [
        {
          key: 'sinkCabinetCount',
          label: 'sink_cabinet_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'sinkCabinetInstallationYear',
          label: 'sink_cabinet_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('fixtures', 'cabinet')],
      items: [
        {
          key: 'cabinetCount',
          label: 'cabinet_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'cabinetInstallationYear',
          label: 'cabinet_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('fixtures', 'island')],
      items: [
        {
          key: 'islandCount',
          label: 'island_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'islandInstallationYear',
          label: 'island_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      ...Row,
      visibleIfValues: [IfCustomSelected('fixtures', 'shelf')],
      items: [
        {
          key: 'shelfCount',
          label: 'shelf_count',
          ...Input,
          unit: 'kpl'
        },
        {
          key: 'shelfInstallationYear',
          label: 'shelf_installation_year',
          ...Select,
          options: YearOptions()
        }
      ]
    },
    {
      key: 'otherFixtures',
      label: 'other_fixtures',
      placeholder: 'enter_more_specific_description',
      visibleIfValues: [IfOtherSelected('fixtures')],
      ...Input
    }
  ]
]

/*
const CoolingSection: any = [
]
*/

export const WallLayout = [
  {
    title: CoatingsAndMaterials,
    sections: CoatingsAndMaterialsSection('wall')
  },
  {
    title: WindowsAndDoors,
    sections: WindowsAndDoorsSection('wall')
  },
  {
    title: Heating,
    sections: HeatingSection
  },
  {
    title: WaterFixtures,
    sections: WaterFixturesSection
  },
  {
    title: Drainage,
    sections: DrainageSection
  },
  {
    title: WaterProofing,
    sections: WaterProofingSection
  },
  {
    title: Ventilation,
    sections: VentilationSection
  },
  {
    title: SocketsAndLighting,
    sections: SocketAndLightingSection
  },
  {
    title: Hearths,
    sections: HearthsSection
  },
  {
    title: ElectricalDevices,
    sections: ElectricalDevicesSection('wall')
  },
  {
    title: Fixtures,
    sections: FixturesSection
  },
  /*
  {
    title: Cooling,
    sections: CoolingSection
  }
  */
]

export const CeilingLayout = [
  {
    title: CoatingsAndMaterials,
    sections: CoatingsAndMaterialsSection('ceiling')
  },
  {
    title: WindowsAndDoors,
    sections: WindowsAndDoorsSection('ceiling')
  },
  {
    title: Ventilation,
    sections: VentilationSection
  },
  {
    title: SocketsAndLighting,
    sections: SocketAndLightingSection
  },
  {
    title: Hearths,
    sections: HearthsSection
  },
  {
    title: ElectricalDevices,
    sections: ElectricalDevicesSection('ceiling')
  },
  {
    title: Fixtures,
    sections: FixturesSection
  },
  /*
  {
    title: Cooling,
    sections: CoolingSection
  }
  */
]

export const FloorLayout = [
  {
    title: CoatingsAndMaterials,
    sections: CoatingsAndMaterialsSection('floor')
  },
  {
    title: Heating,
    sections: HeatingSection
  },
  {
    title: WaterFixtures,
    sections: WaterFixturesSection
  },
  {
    title: Drainage,
    sections: DrainageSection
  },
  {
    title: WaterProofing,
    sections: WaterProofingSection
  },
  {
    title: Ventilation,
    sections: VentilationSection
  },
  {
    title: SocketsAndLighting,
    sections: SocketAndLightingSection
  },
  {
    title: Hearths,
    sections: HearthsSection
  },
  {
    title: ElectricalDevices,
    sections: ElectricalDevicesSection('floor')
  },
  {
    title: Fixtures,
    sections: FixturesSection
  },
  /*
  {
    title: Cooling,
    sections: CoolingSection
  }
  */
]